function check_focus(was, bezeichnung) {
    if (was.value == bezeichnung) {
        was.value = "";
    }
}

function check_blur(was, bezeichnung) {
    if (was.value == "" || was.value == " " || was.value == "  " || was.value == "   ") {
        was.value = bezeichnung;
    }
}

function prod_bildzoom(src, titel) {
    var el_div = document.createElement("div");
    var el_h = document.createElement("div");
    var el_img = new Image();
    el_div.id = "prod_bildzoom_bg";
    el_h.id = "prod_bildzoom_ueberschrift";
    el_img.id = "prod_bildzoom";
    el_h.innerHTML = "<div style='float:left;width:100%;white-space:nowrap;overflow:hidden;'>" + titel + "</div><span class='prod_bildzoom_icon icon-cross'></span>";
    document.body.appendChild(el_div);
    document.body.appendChild(el_h);
    document.body.appendChild(el_img);
    el_img.onload = function () {
        var el_img = document.getElementById("prod_bildzoom");
        var el_div = document.getElementById("prod_bildzoom_bg");
        if (el_img.width > document.body.clientWidth * 0.8) {
            var ratio = el_img.height / el_img.width;
            el_img.height = document.body.clientWidth * 0.8 * ratio;
            el_img.width = document.body.clientWidth * 0.8;
        }
        el_img.style.top = parseInt(document.documentElement.clientHeight / 2 - el_img.height / 2) + "px";
        el_img.style.left = parseInt(document.documentElement.clientWidth / 2 - el_img.width / 2) + "px";
        el_h.style.top = parseInt(document.documentElement.clientHeight / 2 - el_img.height / 2 - 30) + "px";
        el_h.style.left = parseInt(document.documentElement.clientWidth / 2 - el_img.width / 2) + "px";
        el_h.style.width = (el_img.width) + "px";

        el_h.style.zIndex = 10002;
        window.onresize = function () {
            var el_img = document.getElementById("prod_bildzoom");
            var el_h = document.getElementById("prod_bildzoom_ueberschrift");
            el_img.style.top = parseInt(document.documentElement.clientHeight / 2 - el_img.height / 2) + "px";
            el_img.style.left = parseInt(document.documentElement.clientWidth / 2 - el_img.width / 2) + "px";
            el_h.style.top = parseInt(document.documentElement.clientHeight / 2 - el_img.height / 2 - 30) + "px";
            el_h.style.left = parseInt(document.documentElement.clientWidth / 2 - el_img.width / 2) + "px";
        };
        el_img.onclick = el_div.onclick = el_h.onclick = function () {
            document.body.removeChild(document.getElementById("prod_bildzoom"));
            document.body.removeChild(document.getElementById("prod_bildzoom_bg"));
            document.body.removeChild(document.getElementById("prod_bildzoom_ueberschrift"));
            window.onresize = function () {
            }; // the power of IE
        };
    };
    el_img.src = src;
}

function nextheaderimage(p, manual, cs) {
    p_curr = p;
    for (n = 1; n <= 4; n++) {
        p++;
        if (p > 4) p = 1;
        if (document.getElementById('headerimage' + p + cs) != null) break;
    }

    headerbildfade(p_curr, p, 0, cs);

    if (manual == false) {
        window.setTimeout(function () {nextheaderimage(p, false, cs)}, 5000);
    }
}


function headerbildfade(p_old, p_new, step, cs) {
    el_new = document.getElementById('headerimage' + p_new + cs);
    el_old = document.getElementById('headerimage' + p_old + cs);

    if (step < 10) {
        el_new.style.opacity = '0.' + (step);
        el_new.style.filter = 'alpha(opacity=' + (step) + '0)';

        el_old.style.opacity = '' + (1.0 - (step / 10)) + '';
        el_old.style.filter = 'alpha(opacity=' + (100 - (step * 10));

        if (step == 0) {
            el_new.style.display = '';
            el_new.style.zIndex = 20;
            el_old.style.zIndex = 10;
        }

        if (step == 5) {
            if (document.getElementById('headerimagelink' + p_new)) document.getElementById('headerimagelink' + p_new).style.display = '';
            if (document.getElementById('headerimagelink' + p_old)) document.getElementById('headerimagelink' + p_old).style.display = 'none';
        }

        step++;
        window.setTimeout(function(){headerbildfade(p_old, p_new, step, cs)}, 75);
    } else {
        el_new.style.opacity = '1.0';
        el_new.style.filter = 'alpha(opacity=100)';
        el_old.style.display = 'none';
    }
}
$(document).ready(function () {
    $(".js-tabelle_overlay_open").click(function () {
        $this = $(this);

        $.ajax({
            type: "POST",
            url: $(this).data("href"),
            data: {product:$this.data("product"),headline:$this.closest("tr").find("td:first").text()},
            async: true
        }).done(function (respData) {
            $(".js-body").addClass("tabelle_body");
            $(".js-tabelle_overlay_main").html(respData);
            $(".js-tabelle_overlay").show();
        });
    });

    $(".js-tabelle_overlay_close").click(function () {
        $(".js-body").removeClass("tabelle_body");
        $(".js-tabelle_overlay").hide();
    });
});

/**
 * Created by hr on 18.01.2016 - wird nur in der Website verwendent
 */
function getUrl(url){
    url = hx.replace(url,'http://','@');
    document.location.href='mailto:'+url;
}

function nl_abmelden(hash,callbackFunction,root) {
    var abmelden = hx.httpRequestObject();
    abmelden.setAction(root+'admin/newsletter/newsletter_abmelden_ajax.php');
    abmelden.addPOSTParam('hash', hash);
    abmelden.callbackFunc = callbackFunction;
    abmelden.exec();
}
$('document').ready(function(){
    // Thumb-URL nachladen bei Mouseover (File Listen)
    $('.medienpool_file_a').mouseenter(function(){
        var id = $(this).data('id');
        var href = $('#medienpool_thumb_link_'+id).val();
        $('#thumb_'+id).prop('src',href);
    });

});

function setMedienpoolReference(id,typ,filename,fieldname,fieldtype) {
    if (top.window.opener && fieldname.length > 0) {
        if (fieldtype == "ref") {
            // Aufruf durch Reference-Feld
            if (top.window.opener.setHXFormFieldReference) {
                top.window.opener.setHXFormFieldReference(fieldname, id, typ, filename);
            }
        } else if (fieldtype == "html"){
            // Aufruf durch TextAreaHTML, da bei der Anzeige der Textinhalt durchgeparset wird, werden die Links per Platzhalter gesetzt
            if (top.window.opener.setHXFormFieldTextareaHTMLLink) {
                if(typ == "file") typ = "hxfile";
                if(typ == "page") typ = "hxpg";
                if(typ == "bookmark") typ = "hxbm";

                top.window.opener.setHXFormFieldTextareaHTMLLink(fieldname, "#"+typ+id+"#");
            }
        }
        top.window.close();
    }
}

function contenteditorRefreshOpenerPage(root,idp,idcb) {
    if (window.opener) {
        var top = 0;
        top = $(window.opener).scrollTop();
        window.opener.document.location.reload(true);
        /*
        $(window.opener.document).on("pageload",function() {
            $('html, body').animate({scrollTop: top, scrollLeft: 0}, 2000);
            console.log(top);
        });
        */
        setTimeout('$(window.opener).scrollTop('+top+')', 1000);

    }
}


function contenteditorCloseWindow() {
    window.close();
}

function contenteditorFormSwitchLang(id_cmshx_lang) {
    $('#lang_sel').val(id_cmshx_lang);
    $('[id^=contenteditorFormLang]').hide();
    $('#contenteditorFormLang'+id_cmshx_lang).show();
}

function checkPasswordFormat(pw1_obj, pw2_obj, errorArray) {
    if (pw1_obj.value != pw2_obj.value) {
        alert(errorArray[0]);
        pw1_obj.focus();
        pw1_obj.select();
        return false;
    }

    if(pw1_obj.value != '#nochange#') {
        if (pw1_obj.value.length < 8) {
            alert(errorArray[1]);
            pw1_obj.focus();
            pw1_obj.select();
            return false;
        } else {
            //var characterAllowedInString = pw1_obj.value.search(/^[a-zA-Z0-9+-\\\*%=!\\\?_@\\\$#]+$/);
            var characterAllowedInString = pw1_obj.value.search(/^[a-z\u00c4\u00e4\u00d6\u00f6\u00dc\u00fcA-Z0-9+-\\\*%=!\\\?_@\\\$#]+$/);

            if(characterAllowedInString == -1) {
                alert(errorArray[2]);
                pw1_obj.focus();
                pw1_obj.select();
                return false;
            } else {
                var smallLetterInString = pw1_obj.value.search(/[a-z]/);
                var capitalLetterInString = pw1_obj.value.search(/[A-Z]/);
                var numberInString = pw1_obj.value.search(/[0-9]/);

                if((smallLetterInString == -1 && capitalLetterInString == -1) || numberInString == -1) {
                    alert(errorArray[3]);
                    pw1_obj.focus();
                    pw1_obj.select();
                    return false;
                }
            }

        }
    }
    return true;
}

/**
 * Ueberpruefen eines Pfad angabe
 * @param {string} path
 * @returns {boolean}
 */
hx.isValidPath = function (path) {
    var path_pattern = new RegExp('^(((\\/)?[a-z]|[0-9]){1,}(\\/|\\-|\\_)?)*$');

    if(path == "") return true;

    if(path_pattern.test(path)){
        return true;
    }else{
        return false;
    }
};
function libhx_anw() {
}

libhx_anw.open_help = function (root, modulename, helpkey) {
    window.open(root + "libhx/anw/help/index.php?modulename=" + modulename + "&k=" + helpkey + "",
        "help", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,width=850,height=700,left=100,top=100,resizable=yes,dependent=1").focus();
};

libhx_anw.open_credits = function (root) {
    window.open(root + "libhx/anw/credits/index.php", "credits", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,width=480,height=303,resizable=no,dependent=1").focus();
};


// Event-Handler fuer FormFieldDate und FormFieldDateTime
function hx_date_keypressed(field, code, heute_h, heute_m) {
    var t = 0, m = 0, j = 0, d;
    var at = 0, am = 0, aj = 0, ad;
    d = new Date();
    ad = date_input2date(field);
    if (ad != null) {
        at = ad.getDate();
        am = ad.getMonth();
        aj = ad.getFullYear();
    }
    t = d.getDate();
    m = d.getMonth();
    j = d.getFullYear();
    h = d.getHours();
    n = d.getMinutes();

    if (code >= 48 && code <= 57) {
        return true;
    } // 0123456789


    /* veraltet
    if (code >= 96 && code <= 105 && window.event && !window.chrome) {
        return true;
    } // 0123456789 Numpad (nur IE
    */

    if (code >= 37 && code <= 40) {
        return true;
    } // ArrowKeys
    if (code == 0 || code == 36 || code == 35 || code == 190 || code == 8 || code == 13 || code == 46 || code == 9) {
        return true;
    }
    if (code == 27) {
        document.f.elements[field].value = "";
        return true;
    }
    if (code == 72 || code == 104 || code == 84 || code == 116) {
        date_insert(field, j, m, t, heute_h, heute_m);
        return false;
    }
    if (code == 187 || code == 43 || code == 107) {
        if (ad != null) date_insert(field, aj, am, at + 1, 0, 0);
        return false;
    }
    if (code == 189 || code == 45 || code == 109) {
        if (ad != null) date_insert(field, aj, am, at - 1, 0, 0);
        return false;
    }
    if (code == 99 || code == 118) {
        return true;
    } // Ctrl-C , Ctrl-V in FF
    if (code == 67 || code == 86) {
        return true;
    } // Ctrl-C , Ctrl-V in IE
    //alert(code);
    return false;
}

// Event-Handler fuer FormFieldDate und FormFieldDateTime
function date_keypressed(field, code, heute_h, heute_m) {
    var t = 0, m = 0, j = 0, d;
    var at = 0, am = 0, aj = 0, ad;
    d = new Date();
    ad = date_input2date(field);
    if (ad != null) {
        at = ad.getDate();
        am = ad.getMonth();
        aj = ad.getFullYear();
    }
    t = d.getDate();
    m = d.getMonth();
    j = d.getFullYear();
    h = d.getHours();
    n = d.getMinutes();

    if (code >= 48 && code <= 57) {
        return true;
    } // 0123456789
    if (code >= 96 && code <= 105 && window.event && !window.chrome) {
        return true;
    } // 0123456789 Numpad (nur IE

    if (code >= 37 && code <= 40) {
        return true;
    } // ArrowKeys
    if (code == 0 || code == 36 || code == 35 || code == 190 || code == 8 || code == 13 || code == 46 || code == 9) {
        return true;
    }
    if (code == 27) {
        document.f.elements[field].value = "";
        return true;
    }
    if (code == 72 || code == 104 || code == 84 || code == 116) {
        date_insert(field, j, m, t, heute_h, heute_m);
        return false;
    }
    if (code == 187 || code == 43 || code == 107) {
        if (ad != null) date_insert(field, aj, am, at + 1, 0, 0);
        return false;
    }
    if (code == 189 || code == 45 || code == 109) {
        if (ad != null) date_insert(field, aj, am, at - 1, 0, 0);
        return false;
    }
    if (code == 99 || code == 118) {
        return true;
    } // Ctrl-C , Ctrl-V in FF
    if (code == 67 || code == 86) {
        return true;
    } // Ctrl-C , Ctrl-V in IE
    //alert(code);
    return false;
}

// Helper-Funktion fuer FormFieldDate
function date_insert(formfield, j, m, t, h, n) {
    var oldval = document.f[formfield].value;
    if (h + "" == "") h = 0;
    if (n + "" == "") n = 0;
    var d = new Date(j, m, t, h, n, 0);
    t = "" + d.getDate();
    m = "" + (d.getMonth() + 1);
    j = "" + d.getFullYear();
    if (t.length == 1) t = "0" + t;
    if (m.length == 1) m = "0" + m;
    h = "" + d.getHours();
    n = "" + d.getMinutes();
    if (h.length == 1) h = "0" + h;
    if (n.length == 1) n = "0" + n;
    document.f.elements[formfield].value = t + "." + m + "." + j;
    document.f.elements[formfield].focus;
    document.f.elements[formfield].select;
    if (document.f.elements[formfield + "_time"]) {
        if (h + n != 0) document.f.elements[formfield + "_time"].value = "" + h + ":" + n;
        //if (h+n !=0 ) document.f.elements[formfield + "_time"].value="0:00";
    }

    if (oldval != document.f[formfield].value) {
        hx.eventFire(document.f[formfield], 'change');
    }

}

// Helper-Funktion fuer FormFieldDate
function date_input2date(formfield) {
    var t1 = document.f.elements[formfield].value;
    var d;
    var t2 = t1;
    var t = 0, m = 0, j = 0;
    var tx = 0, mx = 0, jx = 0;
    if (t1 == "") {
        return null;
    }
    var tmp = t1.split(".");
    if (tmp.length != 3 && tmp.length != 1) {
        return null;
    }
    if (tmp.length == 1) {
        if (t2.length == 8) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 4);
        } else if (t2.length == 6) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 2);
        } else if (t2.length == 4) {
            kwx = t2.substr(0, 2);
            jx = t2.substr(2, 2);
            j = parseInt(jx, 10);
            kw = parseInt(kwx, 10);
            if (isNaN(kw) || isNaN(j)) {
                return null;
            }
            kw = kw - 1;
            j = j + 2000;
            // ersten Donnerstag des Jahres:
            dat_donn = new Date(j, 0, 1);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 2);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 3);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 4);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 5);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 6);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 7);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 8);

            // Jetzt Montag suchen
            dat_mon = dat_donn;
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);

            datum = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() + (kw * 7));
            tx = datum.getDate();
            mx = datum.getMonth() + 1;
            jx = datum.getFullYear();
        } else {
            return null;
        }
        t = parseInt(tx, 10);
        m = parseInt(mx, 10);
        j = parseInt(jx, 10);
    }
    if (tmp.length == 3) {
        t = parseInt(tmp[0], 10);
        m = parseInt(tmp[1], 10);
        j = parseInt(tmp[2], 10);
    }
    if (isNaN(t) || isNaN(m) || isNaN(j)) {
        return null;
    }
    if (t < 1 || t > 31) {
        return null;
    }
    if (m < 1 || m > 12) {
        return null;
    }
    if (j < 70 && j >= 0) {
        j = j + 2000;
    }
    if (j >= 70 && j < 100) {
        j = j + 1900;
    }
    if (j < 1900 || j > 2100) {
        return null;
    }
    d = new Date(j, m - 1, t);
    return d;
}

// Helper-Funktion fuer FormFieldDate
function date_formatdateinput(feld) {
    var z = date_input2date(feld);
    if (z != null) {
        var j = z.getFullYear();
        var m = z.getMonth();
        var d = z.getDate();
        date_insert(feld, j, m, d, 0, 0);
    }
}


function calOverlayInit(fieldname, root, dict) {
    // Overlay erstellen
    if (!document.getElementById("calendar_" + fieldname)) {
        var tempNode = document.createElement("div");
        var tempClass = document.createAttribute("class");
        tempClass.nodeValue = "calOverlay";
        var tempId = document.createAttribute("id");
        tempId.nodeValue = "calendar_" + fieldname;
        var tempTabIdx = document.createAttribute("tabindex");
        tempTabIdx.nodeValue = document.f[fieldname].getAttribute("tabindex");
        tempNode.setAttributeNode(tempClass);
        tempNode.setAttributeNode(tempId);
        tempNode.setAttributeNode(tempTabIdx);
        document.f.appendChild(tempNode);
    }

    calOverlayInit.root = root;

    // Schliessen wenn man wieder aufs "�ffnensymbol" klickt
    if (calOverlayInit.fieldname == fieldname) {
        //calOverlayInit.fieldname = undefined;
        calOverlayClose();
        return;
    }
    calOverlayInit.dict = dict;
    // Aktuellen Kalender schliessen wenn ein anderer ge�ffnet wird
    if (calOverlayShow.aktCal != undefined) {
        calOverlayClose();
    }
    calOverlayInit.fieldname = fieldname;
    // Datum in Formfield formatieren
    calOverlayGetMonthGrid.chosen = date_input2date(fieldname);
    if (calOverlayGetMonthGrid.chosen != null) date_insert(fieldname, calOverlayGetMonthGrid.chosen.getFullYear(), calOverlayGetMonthGrid.chosen.getMonth(), calOverlayGetMonthGrid.chosen.getDate(), 0, 0);

    // Wenn gebl�ttert wird
    if (calOverlayGetMonthGrid.chosen) {
        calOverlayShow.aktMonat = calOverlayGetMonthGrid.chosen.getMonth();
        calOverlayShow.aktJahr = calOverlayGetMonthGrid.chosen.getFullYear();
        // Wenn Kalender neu ge�ffnet wird
    } else {
        calOverlayShow.aktMonat = new Date().getMonth();
        calOverlayShow.aktJahr = new Date().getFullYear();
    }
    calOverlayShow(calOverlayInit.fieldname);

    //document.getElementById("calendar_"+fieldname).onblur = calOverlayClose;

    var t = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day_chosen")[0];
    if (t) hx.classNameAdd(t, 'calOverlay_active');
}

function calOverlayClose() {
    if (document.getElementById("calendar_" + calOverlayShow.aktCal)) document.getElementById("calendar_" + calOverlayShow.aktCal).style.display = "none";
    if (document.f[calOverlayInit.fieldname]) document.f[calOverlayInit.fieldname].focus();
    var act = hx.objByCssSelector("#calendar" + calOverlayInit.fieldname + " .calOverlay_active")[0];
    if (act) hx.classNameRemove(act, "calOverlay_active");
    calOverlayShow.aktCal = undefined;
    calOverlayInit.fieldname = undefined;
}

function calOverlayShow(fieldname) {
    if (calOverlayShow.aktMonat > 11) {
        calOverlayShow.aktMonat = 0;
        calOverlayShow.aktJahr++;
    } else if (calOverlayShow.aktMonat < 0) {
        calOverlayShow.aktMonat = 11;
        calOverlayShow.aktJahr--;
    }
    var nextMonat = calOverlayShow.aktMonat + 1;
    var nextJahr = calOverlayShow.aktJahr;
    if (nextMonat > 11) {
        nextMonat = 0;
        nextJahr++;
    } else if (nextMonat < 0) {
        nextMonat = 11;
        nextJahr--;
    }
    var html = "";
    html += "<img class='hx-icon-on calOverlay_close'      src='" + calOverlayInit.root + "libhx/img/form/date/close.png' onclick=\"calOverlayClose(calOverlayShow.aktCal);\">";
    html += "<img class='hx-icon-on calOverlay_shiftleft'  src='" + calOverlayInit.root + "libhx/img/form/date/button_left.png' onmousedown=\"calOverlayShift(-1,'click');return false;\"> ";
    html += "<img class='hx-icon-on calOverlay_shifttoday' src='" + calOverlayInit.root + "libhx/img/form/date/button_heute.png' onmousedown=\"calOverlayShift(0,'click');return false;\"> ";
    html += "<img class='hx-icon-on calOverlay_shiftright' src='" + calOverlayInit.root + "libhx/img/form/date/button_right.png' onmousedown=\"calOverlayShift(1,'click');return false;\">";
    html += "<div class='calOverlay_month_container_head1'>";
    html += "" + calOverlayInit.dict[calOverlayShow.aktMonat] + " " + calOverlayShow.aktJahr;
    html += "</div>";
    html += "<div class='calOverlay_month_container_head2'>";
    html += "" + calOverlayInit.dict[nextMonat] + " " + nextJahr;
    html += "</div>";
    html += "<div class='calOverlay_month_container1'>";
    html += calOverlayGetMonthGrid(calOverlayShow.aktJahr, calOverlayShow.aktMonat);
    html += "</div>";
    html += "<div class='calOverlay_month_container2'>";
    html += calOverlayGetMonthGrid(nextJahr, nextMonat);
    html += "</div>";
    calOverlayShow.aktCal = calOverlayInit.fieldname;
    var calendar = document.getElementById("calendar_" + calOverlayInit.fieldname);
    calendar.onblur = function () {
        calOverlayClose();
    };


    calendar.onkeydown = function (e) {
        if (!e) e = window.event;
        if (!e.keyCode) e.keyCode = e.which;
        var act = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_active")[0];
        if (!act) {
            act = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day_chosen")[0];
//            var noChosenDayFound = false;
            if (!act) {
//                var t = hx.objByCssSelector("#calendar_"+calOverlayInit.fieldname+" .calOverlay_month_container1 .calOverlay_day")[0];
//                if(t)hx.classNameAdd(t,'calOverlay_active');
//                noChosenDayFound = true;
                act = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day")[0];
            }

            hx.classNameAdd(act, "calOverlay_active");
            act = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_active")[0];

        }

        var obj;
        var index;

        var objs = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day");

        for (var i = 0; i < objs.length; i++) {
            if (act === objs[i]) {
                obj = act;
                index = i;
                break;
            }
        }
        var move = 0;
        // leertaste
        if (hx.value_in(e.keyCode, 32, 13)) {
            obj.onmousedown();
            // escape
        } else if (e.keyCode == 27) {
            calOverlayClose(calOverlayShow.aktCal);
        } else if (e.keyCode == 37) {
            move = -1;
        } else if (e.keyCode == 39) {
            move = 1;
        } else if (e.keyCode == 38) {
            move = -7;
        } else if (e.keyCode == 40) {
            move = +7;
        }
        obj = objs[index + move];
        if (!obj) {
            var direction = move / Math.abs(move);
            calOverlayShift(direction);
            if (direction > 0) {
                obj = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day")[0];
            } else {
                obj = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day");
                obj = obj[obj.length - 1];
            }
        }


        if (obj) {
            hx.classNameRemove(act, "calOverlay_active");
            hx.classNameAdd(obj, "calOverlay_active");

        }
        return false;
    };
    calendar.innerHTML = html;
    calendar.style.display = "block";


    var fieldOffset = calOverlayOffset(document.forms.f.elements[calOverlayInit.fieldname]);
    calendar.style.left = fieldOffset.left + "px";
    calendar.style.top = (fieldOffset.top + 24) + "px";
    document.getElementById("calendar_" + fieldname).focus();
}

function calOverlayGetMonthGrid(year, month) {
    calOverlayGetMonthGrid.chosen;
    // Erster des Monats
    var detailDate = new Date(year, month, 1, 0, 0, 0, 0);
    var html = "";
    var tage_count = 0;
    var erster_tag;
    var letzter_tag;
    for (var i = 12; i < 19; i++) html += "<div class='calOverlay_gridelement_head calOverlay_day_head'>" + calOverlayInit.dict[i] + "</div>";
    html += "<div class='calOverlay_gridelement_head calOverlay_kw_head'>" + calOverlayInit.dict[19] + "</div>";
    while (detailDate.getMonth() == month) {
        detailDate.setDate(detailDate.getDate() + 1);
        tage_count++;
    }
    erster_tag = new Date(year, month, 1, 0, 0, 0, 0).getDay() - 1;
    letzter_tag = new Date(year, month, tage_count, 0, 0, 0, 0).getDay() - 1;
    if (erster_tag == -1) erster_tag = 6;
    if (letzter_tag == -1) letzter_tag = 6;
    var all_count = 0;
    // leere Wochentage auff�llen bis der 1. beginnt
    for (var i = 0; i < erster_tag; i++) {
        if (i > 4) {
            html += "<div class='calOverlay_gridelement_day calOverlay_day_empty calOverlay_day_weekend'></div>";
        } else {
            html += "<div class='calOverlay_gridelement_day calOverlay_day_empty'></div>";
        }
        all_count++;
    }
    // vom 1. bis zum letzten Monatstag auff�llen, nach jedem Wochenende die KW anzeigen
    var today = new Date();
    for (var i = 1; i <= tage_count; i++) {
        var addClass = "";
        var thisDay = new Date(year, month, i, 0, 0, 0, 0);
        // Vergangene Tage einf�rben
        if (thisDay < today) {
            addClass = " calOverlay_day_past ";
        }
        // Wochenenden markieren
        if (thisDay.getDay() == 6 || thisDay.getDay() == 0) addClass += " calOverlay_day_weekend ";
        // Heute markieren
        if (i == today.getDate() && year == today.getFullYear() && month == today.getMonth()) {
            addClass = " calOverlay_day_today ";
        }

        // Gew�hlten Tag markieren
        if (calOverlayGetMonthGrid.chosen && i == calOverlayGetMonthGrid.chosen.getDate() && year == calOverlayGetMonthGrid.chosen.getFullYear() && month == calOverlayGetMonthGrid.chosen.getMonth()) {
            addClass += " calOverlay_day_chosen ";
        }


        html += "<div onmousedown=\"calOverlayGetMonthGrid.chosen = new Date(" + year + "," + month + "," + i + ",0,0,0,0); date_insert('" + calOverlayInit.fieldname + "'," + year + "," + month + "," + i + ",0,0);calOverlayClicked();calOverlayClose();calOverlayInit.fieldname = undefined;\" class='calOverlay_gridelement_day calOverlay_day " + addClass + "'>" + i + "</div>";
        all_count++;
        if (all_count % 7 == 0) html += "<div class='calOverlay_gridelement_day calOverlay_kw'>" + getKW(thisDay) + "</div>";

    }
    // letzte Woche mit leeren Wochentagen auff�llen falls Sonntag nicht der letzte Tag ist
    for (var i = letzter_tag; i <= 5; i++) {
        if (i > 3) {
            html += "<div class='calOverlay_gridelement_day calOverlay_day_empty calOverlay_day_weekend'></div>";
        } else {
            html += "<div class='calOverlay_gridelement_day calOverlay_day_empty'></div>";
        }
        all_count++;
    }
    // Wenn Monat auf 5 Wochen verteilt ist
    if (all_count == 35) {
        if (letzter_tag != 6) html += "<div class='calOverlay_gridelement_day calOverlay_kw'>" + getKW(new Date(year, month, tage_count, 0, 0, 0, 0)) + "</div>";
        // Leere 6. Woche anzeigen
        for (var i = 1; i <= 7; i++) {
            if (i > 5) {
                html += "<div class='calOverlay_gridelement_day calOverlay_day_empty calOverlay_day_weekend'></div>";
            } else {
                html += "<div class='calOverlay_gridelement_day calOverlay_day_empty'></div>";
            }
        }
        html += "<div class='calOverlay_gridelement_day calOverlay_kw'></div>";
        // Wenn Monat auf 6 Wochen verteilt ist
    } else if (all_count == 42) {
        html += "<div class='calOverlay_gridelement_day calOverlay_kw'>" + getKW(new Date(year, month, tage_count, 0, 0, 0, 0)) + "</div>";
    }
    return html;
}


function calOverlayClicked() {
    if (document.f) {
        if (document.f.elements[calOverlayInit.fieldname]) {
            var fn = window[calOverlayInit.fieldname + "_onchange"];
            if (typeof fn === "function") fn();
        }
    }
}


function calOverlayShift(months, eventname) {
    if (months == 0) {
        calOverlayShow.aktMonat = new Date().getMonth();
        calOverlayShow.aktJahr = new Date().getFullYear();
    } else {
        calOverlayShow.aktMonat += months;
    }

    //var calendar = document.getElementById("calendar_"+calOverlayShow.aktCal);
    calOverlayShow(calOverlayShow.aktCal);

    if (eventname == 'click') {
        var t = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day");


        if (t) {
            hx.foreach(t, function (tobj) {
                hx.classNameRemove(tobj, 'calOverlay_active');
            });

            var t2 = hx.objByCssSelector("#calendar_" + calOverlayInit.fieldname + " .calOverlay_month_container1 .calOverlay_day_chosen")[0];
            if (t2) {
                hx.classNameAdd(t2, 'calOverlay_active');
            } else {
                hx.classNameAdd(t[0], 'calOverlay_active');
            }

        }
    }

}

function calOverlayGetDatestring(day, month, year) {
    month++;
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return day + "." + month + "." + year;
}

function calOverlayOffset(elem) {
    if (!elem) elem = this;
    var x = elem.offsetLeft;
    var y = elem.offsetTop;
    while (elem = elem.offsetParent) {
        x += elem.offsetLeft;
        y += elem.offsetTop;
    }
    return {left: x, top: y};
}


// Event-Handler fuer FormFieldMarker
/*
 function marker_event(feld, onchange_timeout) {
 if (document.f.elements[feld].selectedIndex >= 0) {
 var n = 0;
 var sel = document.f.elements[feld].selectedIndex;
 var temp = document.f.elements[feld].options[document.f.elements[feld].selectedIndex];
 if (temp.text.substring(0, 3) == "[x]") {
 temp.text = "[ ] " + temp.text.substring(4, 1000);
 } else {
 temp.text = "[x] " + temp.text.substring(4, 1000);
 }

 document.f.elements[feld].options[document.f.elements[feld].selectedIndex].text = temp.text;

 marker_setid(feld, undefined, onchange_timeout);
 if (temp.text.substring(4, 6) != "- ") {
 // Rekursiv
 for (n = sel + 1; n < document.f.elements[feld].options.length; n++) {
 if (document.f.elements[feld].options[n].text.substring(4, 6) == "- ") {
 document.f.elements[feld].selectedIndex = n;
 marker_event(feld);
 } else {
 break;
 }
 }
 }
 document.f.elements[feld].selectedIndex = sel;
 }
 }
 */

// Event-Handler fuer FormFieldMarker
function marker_event(feld, onchange_timeout) {
    var selectObj = $('[name=' + feld + ']');
    var selectedOptionObj = selectObj.find('option:selected');
    var selectedIndex = selectedOptionObj.index();

    if (selectedIndex >= 0) {
        var selectedOptionHtml = selectedOptionObj.html();

        if (selectedOptionHtml.substring(0, 3) == "[x]") selectedOptionHtml = "[ ] " + selectedOptionHtml.substring(4, 1000);
        else selectedOptionHtml = "[x] " + selectedOptionHtml.substring(4, 1000);

        selectedOptionObj.html(selectedOptionHtml);

        marker_setid(feld, undefined, onchange_timeout);

        if (selectedOptionHtml.substring(4, 6) != "- ") {
            var optionCount = selectObj.length;

            for (var i = (selectedIndex + 1); i < optionCount; i++) {
                var nextOptionObj = selectedOptionObj.next();
                if (nextOptionObj.html().substring(4, 6) == "- ") {
                    selectObj.eq(i).prop('selected', true);
                    marker_event(feld);
                } else {
                    break;
                }
            }
        }

        selectObj.eq(i).prop('selected', true);
    }
}

// Helper-Funktion fuer FormFieldMarker
function marker_setnone(feld) {
    var temp = '';
    var n = 0;
    for (n = 0; n < document.f.elements[feld].options.length; n++) {
        temp = document.f.elements[feld].options[n];
        if (temp.text.substring(0, 3) == "[x]") temp.text = "[ ] " + temp.text.substring(4, 1000);
    }
    marker_setid(feld);
}

// Helper-Funktion fuer FormFieldMarker
function marker_setall(feld) {
    var temp = '';
    var n = 0;
    for (n = 0; n < document.f.elements[feld].options.length; n++) {
        temp = document.f.elements[feld].options[n];
        if (temp.text.substring(0, 3) != "[x]") temp.text = "[x] " + temp.text.substring(4, 1000);
    }
    marker_setid(feld);
}

// Helper-Funktion fuer FormFieldMarker
function marker_invertall(feld) {
    var temp = '';
    var n = 0;
    for (n = 0; n < document.f.elements[feld].options.length; n++) {
        temp = document.f.elements[feld].options[n];
        if (temp.text.substring(0, 3) == "[x]") {
            temp.text = "[ ] " + temp.text.substring(4, 1000);
        } else {
            temp.text = "[x] " + temp.text.substring(4, 1000);
        }
    }
    marker_setid(feld);
}

function marker_sortchecked(feld) {

    var marker = document.f.elements[feld].options;
    var o = {};
    var checked = [], unchecked = [];
    for (n = 0; n < document.f.elements[feld].options.length; n++) {
        o = document.f.elements[feld].options[n];
        if (hx.startsWith(o.text, '[x]')) {
            checked[checked.length] = {text: o.text, value: o.value};
        } else {
            unchecked[unchecked.length] = {text: o.text, value: o.value};
        }
    }

    if (checked.length > 0) {
        var checkedAndUnchecked = checked.concat(unchecked);
        for (i = 0; i < checkedAndUnchecked.length;i++) {
            marker[i].value = checkedAndUnchecked[i].value;
            //.innerHTML benutzt weil .text im ie10 und ie11 marker nicht mehr benutzbar macht
            marker[i].text = checkedAndUnchecked[i].text;
        }
    }

    //weil .text im ie10 und ie11 marker nicht mehr benutzbar machen
    document.f.elements[feld].outerHTML = document.f.elements[feld].outerHTML;

    if (document.f[marker + '_searchfield']) {
        document.f[marker + '_searchfield'].stackBelegung = undefined;
    }
}

// Helper-Funktion fuer FormFieldMarker
/* RD: 27.08.2018 - BACKUP von marker_setid kann ab 27.11.2018 geloescht werden
function marker_filter(feld, marker_ids_filter, update_marker_hiddenfield) {
    var n = 0;
    if (update_marker_hiddenfield === undefined) update_marker_hiddenfield = true;

    marker_ids_filter = ',' + marker_ids_filter + ',';
    eval('entries = ' + feld + '_markerentries');
    var marker = document.f.elements[feld];
    var marker_ids_set = ',' + document.f.elements[feld + '_hidden'].value;


    //Bei ersten Aufruf alle Marker-Eintraege speichern
    if (entries.length == 0) for (n = 0; n < marker.options.length; n++) entries[n] = marker.options[n].text + '|' + marker.options[n].value;
    //Marker leeren
    marker.innerHTML = '';
    //marker.options.length=0; //benoetigt im IE (auch 9) zu viel Zeit
    //Neue Markereintraege ermitteln
    var opt = '';
    for (n = 0; n < entries.length; n++) {
        var text = entries[n].split('|')[0].substring(4, 1000);
        var value = entries[n].split('|')[1];
        if (value != '' && marker_ids_filter.indexOf(',' + value + ',') >= 0) {
            var state = '[ ] ';
            if (marker_ids_set.indexOf(',' + value + ',') >= 0) state = '[x] ';

            opt += '<option value=' + value + ' >' + state + text + '</option>\n';
            //marker.options[marker.options.length]=new Option(state+text,value, false, false);
        }
    }
    //Marker neu befuellen
    var sbox = document.f.elements[feld].outerHTML;
    var keypressEvent = document.f.elements[feld].onkeypress;
    if (sbox == undefined) {
        //Firefox unter Version 11
        document.f.elements[feld].innerHTML = opt;
    } else {
        //ab IE6, Crome, Safari, ab Firefox 11 - outerHTML ist die einzig akzeptabel performante Variante
        //um neue Eintraege in eine Selectbox zu laden
        sbox = sbox.replace(/></g, '>' + opt + '<');
        document.f.elements[feld].outerHTML = sbox;
        document.f.elements[feld].onkeypress = keypressEvent;
    }

    //Wird zB ueber das Marker-Suchfeld gefiltert, so muss der Zustand der
    //nicht sichtbaren Eintraege unveraendert bleiben - d.h. marker_setid() darf nicht aufgerufen werden:

    //Es sollen alle ids im Marker Hiddenfield aktualisiert werden - differenzielles Update
    //unterbinden
    var reset_marker_hiddenfield = true;

    //ids in hiddenfeld aktualisieren
    if (update_marker_hiddenfield) marker_setid(feld, reset_marker_hiddenfield);

    if (window[feld + '_group_selected_on_top']) {
        marker_sortchecked(feld);
    }
}
*/

function marker_filter(feld, marker_ids_filter, update_marker_hiddenfield) {
    var n = 0;
    if (update_marker_hiddenfield === undefined) update_marker_hiddenfield = true;

    var markerField = $("[name='"+feld+"']");
    if (hx.cint($(markerField).data("turbo_loader_chrome_rows"))>1) {
        //Chrome laedt schneller wenn size=1
        $(markerField).attr('size',1);
    }


    marker_ids_filter = ',' + marker_ids_filter + ',';
    eval('entries = ' + feld + '_markerentries');
    var marker = document.f.elements[feld];
    var marker_ids_set = ',' + document.f.elements[feld + '_hidden'].value;

    //Bei ersten Aufruf alle Marker-Eintraege speichern
    if (entries.length == 0) {
        var length = marker.options.length;
        for (n = 0; n < length; n++) {
            var object = marker.options[n];
            entries[n] = object.text + '|' + object.value;
        }
    }

    //Marker leeren - kann vllt weggelassen werden!!
    marker.innerHTML = '';
    //marker.options.length=0; //benoetigt im IE (auch 9) zu viel Zeit

    //Neue Markereintraege ermitteln
    var opt = '';

    var entriesLength = entries.length;
    for (n = 0; n < entriesLength; n++) {
        var entry = entries[n].split('|');

        var text = entry[0].substring(4, 1000);
        var value = entry[1];
        if (value != '' && marker_ids_filter.indexOf(',' + value + ',') >= 0) {
            var state = '[ ] ';
            if (marker_ids_set.indexOf(',' + value + ',') >= 0) state = '[x] ';

            opt += '<option value=' + value + ' >' + state + text + '</option>';
            //marker.options[marker.options.length]=new Option(state+text,value, false, false);
        }
    }

    //Marker neu befuellen
    var sbox = document.f.elements[feld].outerHTML;
    var keypressEvent = document.f.elements[feld].onkeypress;
    if (sbox == undefined) {
        //Firefox unter Version 11
        document.f.elements[feld].innerHTML = opt;
    } else {
        //ab IE6, Crome, Safari, ab Firefox 11 - outerHTML ist die einzig akzeptabel performante Variante
        //um neue Eintraege in eine Selectbox zu laden
        sbox = sbox.replace(/></g, '>' + opt + '<');
        document.f.elements[feld].outerHTML = sbox;
        document.f.elements[feld].onkeypress = keypressEvent;
    }

    //Wird zB ueber das Marker-Suchfeld gefiltert, so muss der Zustand der
    //nicht sichtbaren Eintraege unveraendert bleiben - d.h. marker_setid() darf nicht aufgerufen werden:

    //Es sollen alle ids im Marker Hiddenfield aktualisiert werden - differenzielles Update
    //unterbinden
    var reset_marker_hiddenfield = true;

    //ids in hiddenfeld aktualisieren
    if (update_marker_hiddenfield) marker_setid(feld, reset_marker_hiddenfield);

    if (window[feld + '_group_selected_on_top']) {
        marker_sortchecked(feld);
    }

    markerField = $("[name='"+feld+"']");  //Braucht es nochmal, ansonsten werden attribute nicht gesetzt
    if (hx.cint($(markerField).data("turbo_loader_chrome_rows"))>1) {
        //Size wieder zuruecksetzen (Chrome laedt schneller wenn size=1)
        $(markerField).attr('size',$(markerField).data("turbo_loader_chrome_rows"));
    }

}

// Helper-Funktion fuer FormFieldMarker
/* RD: 27.08.2018 - BACKUP von marker_setid kann ab 27.11.2018 geloescht werden
function marker_setid(feld, reset_marker_hiddenfield, onchange_timeout) {
    var n, ids = new Array();
    if (!marker_setid.onchange_timeouts) marker_setid.onchange_timeouts = new Object();

    if (reset_marker_hiddenfield === undefined) reset_marker_hiddenfield = false;

    if (document.f[feld + "_searchfield"] && reset_marker_hiddenfield == false) {
        //Wurden die Eintraege im Marker ueber das Suchfeld reduziert - so muss das Marker-hiddenfield
        //differenziell aktualisiert werden. Grund: Durch das Suchfeld aktuell nicht sichtbare Eintraege muessen
        // ihren Zustand beibehalten

        //Hiddenfield Eintraege in array merken, wenn Suchfeld angezeigt wird
        ids = hx.explode(",", hx.trim(document.f.elements[feld + "_hidden"].value, ", "));
        if (ids[0] == "") ids = new Array();
    }
    for (n = 0; n < document.f.elements[feld].options.length; n++) {
        if (document.f.elements[feld].options[n].text.substring(0, 3) == "[x]") {
            ids.push(document.f.elements[feld].options[n].value);
        } else {
            for (var i = 0; i < ids.length; i++) {
                if (ids[i] == document.f.elements[feld].options[n].value) {
                    ids.splice(i, 1);
                    break;
                }
            }
        }
    }
    if (document.f[feld + "_searchfield"]) {
        ids = hx.array_unique(ids);
    }
    document.f.elements[feld + "_hidden"].value = "";
    if (ids.length > 0) {
        document.f.elements[feld + "_hidden"].value = hx.implode(",", ids) + ",";
    }
    //eval('type = typeof onchange_'+feld);
    //if (type=='function') eval('onchange_'+feld+'()');
    var fn = window['onchange_' + feld];
    if (typeof fn === 'function') {
        clearTimeout(marker_setid.onchange_timeouts[feld]);//timeout
        if (hx.cint(onchange_timeout) > 0) {
            marker_setid.onchange_timeouts[feld] = setTimeout(fn, hx.cint(onchange_timeout));
        } else {
            fn();
        }
    }
}
*/

function marker_setid(feld, reset_marker_hiddenfield, onchange_timeout) {
    var n, ids = new Array();
    if (!marker_setid.onchange_timeouts) marker_setid.onchange_timeouts = new Object();

    if (reset_marker_hiddenfield === undefined) reset_marker_hiddenfield = false;

    if (document.f[feld + "_searchfield"] && reset_marker_hiddenfield == false) {
        //Wurden die Eintraege im Marker ueber das Suchfeld reduziert - so muss das Marker-hiddenfield
        //differenziell aktualisiert werden. Grund: Durch das Suchfeld aktuell nicht sichtbare Eintraege muessen
        // ihren Zustand beibehalten

        //Hiddenfield Eintraege in array merken, wenn Suchfeld angezeigt wird
        ids = hx.explode(",", hx.trim(document.f.elements[feld + "_hidden"].value, ", "));
        if (ids[0] == "") ids = new Array();
    }

    var length = document.f.elements[feld].options.length;
    for (n = 0; n < length; n++) {
        var object = document.f.elements[feld].options[n];

        if (object.text.substring(0, 3) == "[x]") {
            ids.push(object.value);
        } else {
            var idsLength = ids.length;
            for (var i = 0; i < idsLength; i++) {
                if (ids[i] == object.value) {
                    ids.splice(i, 1);
                    break;
                }
            }
        }
    }
    if (document.f[feld + "_searchfield"]) {
        ids = hx.array_unique(ids);
    }
    document.f.elements[feld + "_hidden"].value = "";
    if (ids.length > 0) {
        document.f.elements[feld + "_hidden"].value = hx.implode(",", ids) + ",";
    }
    //eval('type = typeof onchange_'+feld);
    //if (type=='function') eval('onchange_'+feld+'()');
    var fn = window['onchange_' + feld];
    if (typeof fn === 'function') {
        clearTimeout(marker_setid.onchange_timeouts[feld]);//timeout
        if (hx.cint(onchange_timeout) > 0) {
            marker_setid.onchange_timeouts[feld] = setTimeout(fn, hx.cint(onchange_timeout));
        } else {
            fn();
        }
    }
}

// Helper-Funktion fuer FormFieldMarker
function keypress_marker(name, key) {
    //Durch Tastendruck an den naechsten Eintrag springen
    var keypressed = String.fromCharCode(key);
    keypressed = keypressed.toLowerCase();

    if (!keypress_marker.eingabeCache) keypress_marker.eingabeCache = new Object();
    if (!keypress_marker.eingabeCache[name]) keypress_marker.eingabeCache[name] = "";
    if (!keypress_marker.timeout) keypress_marker.timeout = null;


    keypress_marker.eingabeCache[name] += keypressed;

    clearTimeout(keypress_marker.timeout);
    keypress_marker.timeout = setTimeout(function () {
        keypress_marker.eingabeCache[name] = "";
    }, 1000);

    for (var i = 0; i < document.f.elements[name].options.length; i++) {
        var text = hx.substr(document.f.elements[name].options[i].text, 4);
        if (text.toUpperCase().indexOf(keypress_marker.eingabeCache[name].toUpperCase()) == 0) {
            document.f.elements[name].options[i].selected = true;
            break;
        }
    }


}

// Helper-Funktion fuer FormFieldMarker
//function keypress_marker____________old(el,ev) {
//    var n=0;
//    //Durch Tastendruck an den naechsten Eintrag springen
//    keypressed = String.fromCharCode(ev);
//    keypressed = keypressed.toLowerCase();
//    anzpos   = document.f.elements[el].options.length;
//    startpos = document.f.elements[el].options.selectedIndex;
//    startpos++; //ab dem naechsten Eintrag suchen (noch nicht markiert ergibt -1 -> start bei 0)
//    if (startpos>=anzpos) startpos=0;
//    found = false;
//    for (n=startpos;n<anzpos;n++){
//        if (document.f.elements[el].options[n].text.substring(4,5).toLowerCase()==keypressed){
//            document.f.elements[el].options[n].selected = true;
//            found = true;
//            break;
//        }
//        if (n==(anzpos-1) && (startpos>0 || found==true)) {  //letzter Eintrag erreich: Wurde was gefunden oder nicht von Beginn an gesucht -> wieder an den Anfang
//            startpos = 0;
//            n=-1;
//        }
//    }
//}

// Prueft Textfeld auf korrekte numerische Eingabe
function check_number_field(fieldname, formatmsg, limitmsg, minvalue, maxvalue) {
    if (document.f.elements[fieldname]) {
        var t = document.f.elements[fieldname].value;
        t = replace(t, ' ', '');
        var n = 0, temp = '', temp2 = '';
        for (n = 0; n < t.length; n++) {
            if (t.charAt(n) == ',') {
                temp += '.';
                temp2 += ',';
            } else if (t.charAt(n) != '.') {
                temp += t.charAt(n);
                temp2 += t.charAt(n);
            }
        }
        if (isNaN(temp) == false) {
        } else {
            alert(formatmsg);
            try {
                document.f.elements[fieldname].focus();
            } catch (e) {
            }
            try {
                document.f.elements[fieldname].select();
            } catch (e) {
            }
            return false;
        }
        if (parseFloat(temp) < minvalue || parseFloat(temp) > maxvalue) {
            alert(limitmsg);
            try {
                document.f.elements[fieldname].focus();
            } catch (e) {
            }
            try {
                document.f.elements[fieldname].select();
            } catch (e) {
            }
            return false;
        }
        document.f.elements[fieldname].value = temp2;
    }
}


function AddToMultiFile(fieldname, filename, url, id) {
    var n = 0;
    var o = document.getElementById(fieldname + '_box');
    var ht = o.innerHTML;
    // n�chste freie l�sch-id suchen
    for (n = 0; n < 30; n++) {
        if (document.f.elements[fieldname + '_id_del' + n]) {
        } else {
            break;
        }
    }
    o.innerHTML = ht + "<table border=0 cellspacing=0 cellpadding=2 width='100%'><tr><td width='1%' style='border-bottom:1px solid #c0c0c0;' align=center><input type='checkbox' style='border:0px;' name='dokumente_id_del" + n + "' value='r" + id + "'</td><td width='99%' style='border-bottom:1px solid #c0c0c0;'><a href='" + url + "' target='_blank'>" + filename + "</a></td></table>";
    for (n = 0; n < 30; n++) {
        if (document.f.elements[fieldname + '_id_remote' + n].value + "" == "") {
            document.f.elements[fieldname + '_id_remote' + n].value = id;
            break;
        }
    }
}


function textarea_maxlength(obj) {
    var mlength = obj.getAttribute ? parseInt(obj.getAttribute("maxlength")) : "";
    var mlines = obj.getAttribute ? parseInt(obj.getAttribute("maxlines")) : "";
    text = obj.value;
    var n = 0;
    var lines = 0;
    var cut = 0;
    for (n = 0; n < text.length; n++) {
        if (text.charAt(n) == "\n") lines++;
        if (lines > mlines - 1) {
            cut = n - 1;
            break;
        }
    }


    if (obj.getAttribute && obj.value.length > mlength) {
        obj.value = obj.value.substring(0, mlength);
    }
    if (obj.getAttribute && cut > 0) {
        obj.value = obj.value.substring(0, cut);
    }
}

function multiuploads_updateDokumenteHinzufuegen(name, maxcount) {
    var container = $('#mt_' + name);
    var uploadedFileCountObject = container.find('input[name=\"uploaded_' + name + '\"]');
    var uploadedFileCount = hx.cint(uploadedFileCountObject.val());
    var visibleUploadFiles = container.find('[id^=uicon]:not(#u' + name + 'x)').not(':hidden').length;
    if (uploadedFileCount >= maxcount || visibleUploadFiles >= maxcount) $('#u' + name + 'x').hide();
    else $('#u' + name + 'x').show();
}

function multiuploads_morefiles(name, count) {
    var n = 0;
    var update = true;
    for (n = 0; n < count; n++) {
        if (document.getElementById('u' + name + n).style.display == 'none') {
            document.getElementById('u' + name + n).style.display = '';
            if (n == count - 1) {
                document.getElementById('u' + name + 'x').style.display = 'none';
                update = false;
            }
            break;
        }
        if (n == count - 1) {
            document.getElementById('u' + name + 'x').style.display = 'none';
            update = false;

        }
    }

    if (update) multiuploads_updateDokumenteHinzufuegen(name, count);
}

function multiuploads_toggle_folder(name, count, root) {
    if (document.images["f_" + name]) {
        var tag = document.images["f_" + name].getAttribute("tag");
        if (tag == "closed") {
            // open folder
            document.images["f_" + name].src = root + 'libhx/img/form/multiupload/folder_open.png';
            document.images["f_" + name].setAttribute("tag", "open");
            document.getElementById("mt_" + name).style.display = "";
        } else {
            // close folder
            document.images["f_" + name].src = root + 'libhx/img/form/multiupload/folder_closed.png';
            document.images["f_" + name].setAttribute("tag", "closed");
            document.getElementById("mt_" + name).style.display = "none";
        }
    }
}


function toggle_filedel(fieldname, root) {
    if (document.f.elements[fieldname + "_del"]) {
        if (document.f.elements[fieldname + "_del"].value == "YES") {
            document.f.elements[fieldname + "_del"].value = "";
            document.images["im" + fieldname].src = root + "libhx/img/form/multiupload/del.gif";
        } else {
            document.f.elements[fieldname + "_del"].value = "YES";
            document.images["im" + fieldname].src = root + "libhx/img/form/multiupload/askdel.gif";
        }
    }
}


// Funktionen f�r xxd mm:hh - Felder
function getDHHMMHours(txt) {
    // Format: xx[d] hh:mm
    var s = txt.toLowerCase();
    var t = -1, tt = '';
    var h = -1, hh = '';
    var m = -1, mm = '';
    var z = 0;

    // Tage auslesen
    var pos1 = s.indexOf(' ');
    if (pos1 >= 0) {
        tt = s.substr(0, pos1);
        tt = tt.replace(/d/, '');
        tt = tt.replace(/t/, '');
        tt = tt.replace(/T/, '');
        tt = tt.replace(/D/, '');
        s = s.substr(pos1 + 1, 100);
        t = parseInt(tt, 10);
        if (isNaN(t)) t = 0;
    } else {
        t = 0;
    }
    // Studen und Minuten
    pos1 = s.indexOf(':');
    if (pos1 >= 0) {
        hh = s.substr(0, pos1);
        mm = s.substr(pos1 + 1, 10);
        h = parseInt(hh, 10);
        m = parseInt(mm, 10);
        if (isNaN(h) || isNaN(m)) {
            return null;
        }
    } else {
        return null;
    }

    z = t * 24 + h + (m / 60);
    return Math.round(z * 60);
}

function formatDHHMM(minuten) {
    var t = 0, tt = '';
    var h = 0, hh = '';
    var m = 0, mm = '';
    if (minuten + '' == '') return '';
    var stunden = minuten / 60.0;
    if (stunden + '' == '') return '';
    t = Math.floor(stunden / 24);
    h = Math.floor(stunden) - t * 24;
    m = Math.round((stunden - (t * 24) - h) * 60);
    tt = '' + t;
    hh = '' + h;
    mm = '' + m;
    //if (tt.length<2)tt='0' + tt;
    if (hh.length < 2) hh = '0' + hh;
    if (mm.length < 2) mm = '0' + mm;
    return tt + 'T ' + hh + ':' + mm;
}


// Teil von FormFieldSelector
// Sucht in SELECT-Box nach Suchstring
function form_selector_search(field) {
    var searchtext = document.f.elements["ss" + field].value.toUpperCase();
    var n = 0, t = "";

    var pos = document.f.elements[field].selectedIndex;
    if (pos < 0) pos = 0; else pos++;

    if (searchtext == "") {
        document.f.elements[field].selectedIndex = 0;
        return;
    }

    for (n = pos; n < document.f.elements[field].options.length; n++) {
        t = document.f.elements[field].options[n].text;
        if (t.toUpperCase().indexOf(searchtext) >= 0) {
            document.f.elements[field].selectedIndex = n;
            if (document.f.elements[field].onchange) document.f.elements[field].onchange();
            if (document.f.elements[field].onblur) document.f.elements[field].onblur();
            return;
        }
    }
    if (pos > 0) {
        document.f.elements[field].selectedIndex = 0;
        // Search fromstart
        //form_selector_search(field);
    }
}


/*
 FORMFILEDTIME START
 */
/**
 * Ap - 22.11.2018 - Ist auch im CIS Polierbericht direkt im Einsatz
 *
 * @param value
 * @param withSeconds
 * @param interval
 * @returns {*}
 * @private
 */
function _getFormatedTime(value, withSeconds, interval) {
    if (value != "") {
        var hour = 0;
        var minute = 0;
        var second = 0;
        var formatme = false;

        var split = hx.explode(":", value);
        if (hx.count(split) == 1 && hx.isInt(split[0])) {
            var number = hx.cint(split[0]);
            if (String(number).length == 1 || String(number).length == 2) {
                hour = number;
                if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0) {
                    formatme = true;
                } else if (hour == 24) {
                    formatme = true;
                }
            } else if (String(number).length == 3) {
                hour = hx.cint(String(number).substr(0, 1));
                minute = hx.cint(String(number).substr(1, 2));
                if ((Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, 59) == 59 && Math.min(minute, 0) == 0)) {
                    formatme = true;
                } else if (hour == 24 && minute == 0) {
                    formatme = true;
                }
            } else if (String(number).length == 4) {
                hour = hx.cint(String(number).substr(0, 2));
                minute = hx.cint(String(number).substr(2, 2));
                if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, 59) == 59 && Math.min(minute, 0) == 0) {
                    formatme = true;
                } else if (hour == 24 && minute == 0) {
                    formatme = true;
                }
            } else if (String(number).length == 5) {
                hour = hx.cint(String(number).substr(0, 2));
                minute = hx.cint(String(number).substr(2, 2));
                second = hx.cint(String(number).substr(4, 1));
                if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, second, 59) == 59 && Math.min(minute, second, 0) == 0) {
                    formatme = true;
                } else if (hour == 24 && minute == 0 && second == 0) {
                    formatme = true;
                }
            } else if (String(number).length >= 6) {
                hour = hx.cint(String(number).substr(0, 2));
                minute = hx.cint(String(number).substr(2, 2));
                second = hx.cint(String(number).substr(4, 2));
                if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, second, 59) == 59 && Math.min(minute, second, 0) == 0) {
                    formatme = true;
                } else if (hour == 24 && minute == 0 && second == 0) {
                    formatme = true;
                }
            }
        } else if (hx.count(split) == 2 && hx.isInt(split[0]) && hx.isInt(split[1])) {
            hour = hx.cint(split[0].substr(0, 2));
            minute = hx.cint(split[1].substr(0, 2));
            if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, 59) == 59 && Math.min(minute, 0) == 0) {
                formatme = true;
            } else if (hour == 24 && minute == 0) {
                formatme = true;
            }
        } else if (hx.count(split) == 3 && hx.isInt(split[0]) && hx.isInt(split[1]) && hx.isInt(split[2])) {
            hour = hx.cint(split[0].substr(0, 2));
            minute = hx.cint(split[1].substr(0, 2));
            second = hx.cint(split[2].substr(0, 2));
            if (Math.max(hour, 23) == 23 && Math.min(hour, 0) == 0 && Math.max(minute, second, 59) == 59 && Math.min(minute, second, 0) == 0) {
                formatme = true;
            } else if (hour == 24 && minute == 0 && second == 0) {
                formatme = true;
            }
        }

        if (formatme) {
            var rest = minute % interval;
            if (rest != 0) {
                var grenze = hx.ceil(interval / 2);
                var teile = hx.floor(minute / interval);
                if (rest >= grenze) {
                    teile++;
                }
                minute = teile * interval;
                if (minute >= 60) {
                    minute = 0;
                    hour = hour + 1;
                }
            }

            if (withSeconds) {
                return hx.format00(hour) + ":" + hx.format00(minute) + ":" + hx.format00(second);
            } else {
                return hx.format00(hour) + ":" + hx.format00(minute);
            }
        } else {
            return null;
        }
    } else {
        return value;
    }
}


function formatTimefield(obj, withSeconds, interval) {
    var value = hx.replace(obj.value, " ", "");
    var formatedTime = _getFormatedTime(value, withSeconds, interval);
    if (formatedTime !== null) {
        obj.value = formatedTime;
    }
}

function _getFormatedAktuellDatum(withSeconds) {
    var date = new Date(hx.now());
    if (withSeconds) {
        return hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes()) + ":" + hx.format00(date.getSeconds());
    }
    return hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes());
}

function shortCutsTimefield(obj, event, interval, withSeconds) {
    if (String.fromCharCode(event.which) == "h" || String.fromCharCode(event.which) == "t") {
        obj.value = _getFormatedAktuellDatum(withSeconds)

    } else if (String.fromCharCode(event.which) == "+") {
        if (obj.value == "") {
            obj.value = _getFormatedAktuellDatum(withSeconds);
        }

        var value = _getFormatedTime(obj.value, withSeconds, interval);
        if (value !== null) {
            obj.value = value;
            var hour = hx.cint(hx.substr(obj.value, 0, 2));
            var minute = hx.cint(hx.substr(obj.value, 3, 2));
            var multi = hx.floor(minute / interval) + 1;
            minute = multi * interval;

            if (minute >= 60) {
                hour = hour + 1;
                minute = 0;
            }

            if (hour == 24 && minute > 0) {
                hour = 0;
                minute = interval;
            }

            if (withSeconds) {
                obj.value = hx.format00(hour) + ":" + hx.format00(minute) + ":" + hx.format00(0);
            } else {
                obj.value = obj.value = hx.format00(hour) + ":" + hx.format00(minute);
            }
        }

    } else if (String.fromCharCode(event.which) == "-") {
        if (obj.value == "") {
            obj.value = _getFormatedAktuellDatum(withSeconds);
        }

        var value = _getFormatedTime(obj.value, withSeconds, interval);
        if (value !== null) {
            obj.value = value;
            var hour = hx.cint(hx.substr(obj.value, 0, 2));
            var minute = hx.cint(hx.substr(obj.value, 3, 2));
            if (withSeconds) {
                var second = hx.cint(hx.substr(obj.value, 6, 2));
                if (second > 0) minute++;
            }
            var multi = hx.ceil(minute / interval) - 1;
            minute = multi * interval;

            if (minute < 0) {
                minute = 60 - interval;
                hour = hour - 1;
            }

            if (hour <= -1) hour = 23;

            if (withSeconds) {
                obj.value = hx.format00(hour) + ":" + hx.format00(minute) + ":" + hx.format00(0);
            } else {
                obj.value = obj.value = hx.format00(hour) + ":" + hx.format00(minute);
            }
        }
    }

    var allowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":"];
    var allowedKeycodes = ["13", "46", "37", "38", "39", "40", "8", "9"];
    if (!hx.in_array(allowed, String.fromCharCode(event.which)) && !hx.in_array(allowedKeycodes, String(event.keyCode))) {
        if (event.preventDefault) {
            event.preventDefault();
        }

        event.returnValue = false;
        return false;
    }
}

function formFieldNotNull(obj, errorMessage) {
    if (obj.value == "") {
        alert(errorMessage);
        obj.focus();
        obj.select();
        return false;
    }
    return true;
}

function formFieldTimeFormat(obj, withSeconds, errorMessage, interval) {
    if (_getFormatedTime(obj.value, withSeconds, interval) === null) {
        alert(errorMessage);
        obj.focus();
        obj.select();
        return false;
    }
    return true;
}

/**** HXFormFieldMarkerChecknbox **********/


function HXMarker(objectName, initValues, displayTooltip) {
    var _objectName = objectName;
    var _initValues = initValues;
    var _checkValues = [];
    var domObj = $('div#' + _objectName);
    var _displayTooltip = false;
    if (displayTooltip) _displayTooltip = true;

    var _removeArrayElement = function (arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = $.inArray(what, arr)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    };

    var _updateHidden = function () {
        var val = hx.implode(',', _checkValues);
        $('input[name=' + _objectName + '], input[name=' + _objectName + '_hidden]').val(val);
    };

    var _getSearchFieldValue = function () {
        return $('input[name=' + _objectName + '_searchfield]').val();
    };

    var _addEntry = function (id, content, sort) {
        var html = "<li data-sort=\"" + sort + "\" data-id=\"" + id + "\" data-content=\"" + $("<span>" + content + "</span>").text().toLowerCase() + "\">" + content + "</li>";
        return html;
    };


    domObj.updateHiddenByMarker = function () {
        _checkValues = [];
        $('div#' + _objectName + ' .checked:not(\".hidden\")').each(function (key, obj) {
            _checkValues.push($(this).data('id'));
        });

        _updateHidden();
    };

    domObj.updateMarkerByHidden = function () {
        var obj = $('div#' + _objectName);
        var values = $('input[name=' + _objectName + ']').val();
        var data = hx.explode(',', values);
        $.each(data, function (key, id) {
            if (id > 0) domObj.setChecked(id);
        });
    };

    domObj.clearAllEntry = function (updateHidden) {
        if (updateHidden === undefined) updateHidden = false;
        $('div#' + _objectName + ' li').remove();
        if (updateHidden) {
            _checkValues = [];
            _updateHidden();
        }
    };

    /**
     * @param obj - {1: {id:1, content: 'bla'},2: {id:2, content: 'bla1'}}
     */
    domObj.addEntryByDataObject = function (obj) {
        var html = "";
        var sort = 0;
        $.each(obj, function (key, obj) {
            html += _addEntry(obj.id, obj.content, sort);
            sort++;
        });
        $('div#' + _objectName + " ul.content").append(html);
        domObj.search(_getSearchFieldValue());
    };

    domObj.filterByIds = function (ids) {
        var arr = hx.explode(',', ids);
        $('div#' + _objectName + ' li').addClass('hidden').removeClass('checked').data('checked', 0);
        var divContainer = $('div#' + _objectName);
        divContainer.find('[data-id=\"' + arr.join('\"],[data-id=\"') + '\"]').removeClass('hidden');
        divContainer.find('[data-id=\"' + _checkValues.join('\"],[data-id=\"') + '\"]').addClass('checked').data('checked', 1);
        domObj.updateHiddenByMarker();
    };


    domObj.setFilterByIds = function (ids) {
        domObj.filterByIds(ids);
    };

    domObj.search = function (searchString) {
        if(searchString == undefined) return;
        if (searchString.length != 0) {
            /*
            searchString = searchString.toLowerCase();
            $('div#' + _objectName + ' li[data-content*=\"' + searchString + '\"]').removeClass('searchHidden');
            $('div#' + _objectName + ' li:not([data-content*=\"' + searchString + '\"])').addClass('searchHidden');
            $('div#' + _objectName + ' li:not([data-content*=\"' + searchString + '\"])').find(':not(.searchHidden)').addClass('searchHidden');
            */
            searchString = searchString.toLowerCase();
            $('div#' + _objectName + ' li[data-content*=\"' + searchString + '\"]').removeClass('searchHidden');
            $('div#' + _objectName + ' li:not([data-content*=\"' + searchString + '\"])').addClass('searchHidden');
        } else {
            $('div#' + _objectName + ' li').removeClass('searchHidden');
        }

        domObj.sortASC();
        return true;
    };

    domObj.getAllCheckedValues = function () {
        return _checkValues;
    };

    domObj.getAllVisibleIds = function () {
        var values = [];
        $('div#' + _objectName + ' li:not(\".locked, .hidden\")').each(function () {
            values.push($(this).data('id'));
        });
        return values;
    };

    domObj.checkAllValues = function (callChangeEvt) {
        if (callChangeEvt === undefined) callChangeEvt = true;

        $('div#' + _objectName + ' li:not(\".searchHidden, .locked, .hidden\")').each(function () {
            _checkValues.push($(this).addClass('checked').data('checked', 1).data('id'));
        });
        _updateHidden();
        if (callChangeEvt) $('div#' + _objectName).trigger('change');
    };

    domObj.unCheckAllValues = function (callChangeEvt) {
        if (callChangeEvt === undefined) callChangeEvt = true;

        $('div#' + _objectName + ' li:not(\".searchHidden, .locked, .hidden\")').each(function () {
            _checkValues = _removeArrayElement(_checkValues, $(this).removeClass('checked').data('checked', 0).data('id'));
        });
        _updateHidden();
        if (callChangeEvt) $('div#' + _objectName).trigger('change');
    };

    domObj.invertCheckboxes = function (callChangeEvt) {
        if (callChangeEvt === undefined) callChangeEvt = true;

        $('div#' + _objectName + ' li:not(\".searchHidden, .locked, .hidden\")').each(function () {
            if (hx.cint($(this).data('checked')) == 1) {
                _checkValues = _removeArrayElement(_checkValues, $(this).removeClass('checked').data('checked', 0).data('id'));
            } else {
                _checkValues.push($(this).addClass('checked').data('checked', 1).data('id'));
            }
        });
        _updateHidden();
        if (callChangeEvt) $('div#' + _objectName).trigger('change');
    };

    domObj.setDisabledIds = function (ids, callChangeEvt) {
        if (callChangeEvt === undefined) callChangeEvt = true;
        var arr = hx.explode(',', ids);
        $('div#' + _objectName).find('[data-id=\"' + arr.join('\"],[data-id=\"') + '\"]').addClass('locked');

        if (callChangeEvt) $('div#' + _objectName).trigger('change');
    };

    domObj.sortASC = function () {
        var checkedBoxes = $('div#' + _objectName + ' li.checked');
        var uncheckedBoxes = $('div#' + _objectName + ' li:not(\".checked\")');
        var sortFunction = function (a, b) {
            if ($(a).data('sort') < $(b).data('sort')) return -1;
            return 1;
        };
        if (checkedBoxes.length < uncheckedBoxes.length) {
            checkedBoxes.sort(sortFunction).prependTo('div#' + _objectName + ' > ul');
        } else {
            uncheckedBoxes.sort(sortFunction).appendTo('div#' + _objectName + ' > ul');
        }
    };

    domObj.sortASCAll = function () {
        var checkedBoxes = $('div#' + _objectName + ' li.checked');
        var uncheckedBoxes = $('div#' + _objectName + ' li:not(\".checked\")');

        var sortFunction = function (a, b) {
            if ($(a).data('sort') < $(b).data('sort')) return -1;
            return 1;
        };

        checkedBoxes.sort(sortFunction).prependTo('div#' + _objectName + ' > ul');
        uncheckedBoxes.sort(sortFunction).appendTo('div#' + _objectName + ' > ul');
    };

    domObj.setChecked = function (v, userclick) {
        var addClasses = [];
        addClasses.push('checked');
        if (userclick) {
            $('div#' + _objectName + ' .active').removeClass('active');
            addClasses.push('active');
        }

        $('div#' + _objectName + ' li[data-id=' + v + ']').addClass(hx.implode(' ', addClasses)).data('checked', 1);
        _checkValues.push(v);
        if (userclick) _updateHidden();

    };

    domObj.setUnChecked = function (v, userclick) {
        if (userclick) $('div#' + _objectName + ' .active').removeClass('active');

        $('div#' + _objectName + ' li[data-id=' + v + ']').removeClass('checked').addClass('active').data('checked', 0);
        _checkValues = _removeArrayElement(_checkValues, v);
        if (userclick) _updateHidden();
    };


    var _init = function () {
        $.each(_initValues, function (key, value) {
            domObj.setChecked(value, false);
        });


        //tooltip anzeigen
        if (_displayTooltip) {
            $(document).on('mouseover', 'div#' + _objectName + ' li', function (evt) {
                var titleAttribute = $(this).attr('title');
                if (typeof titleAttribute == 'undefined') $(this).attr('title', $(this).text());
            });
        }

        $(document).on('click', 'div#' + _objectName + ' li', function (evt) {
            if ($(this).hasClass('locked')) return;
            //pruefen ob li angeclickt wurde oder ein element (zb.: icon zum popup oeffnen)
            if ($(evt.target).prop("tagName").toUpperCase() != 'LI') return;

            var checked = $(this).data('checked');
            var id = $(this).data('id');

            if (checked == 1) domObj.setUnChecked(id, true);
            else domObj.setChecked(id, true);

            $('div#' + _objectName).trigger('change');
        });

        $(document).on('change', 'div#' + _objectName, function () {
            var fn = window['onchange_' + _objectName];
            if (typeof fn === 'function') {
                fn();
            }
        });

        $(document).on('focusout', 'div#' + _objectName, function () {
            $(this).find('li.last-focus').removeClass('last-focus');
            $(this).find('.active').removeClass('active').addClass('last-focus');
        });

        $(document).on('focusin', 'div#' + _objectName, function () {
            var focusedElem = $(this).find('li.last-focus');
            if (focusedElem.length == 0 || focusedElem.hasClass('searchHidden') || focusedElem.hasClass('hidden')) {
                $('div#' + _objectName + ' li:not(\".searchHidden, .locked, .hidden\")').first().addClass('active');
                $(this).removeClass('last-focus');
            } else {
                $(this).find('li.last-focus').addClass('active').removeClass('last-focus');
            }
        });

        $(document).on('keydown', 'div#' + _objectName, function (evt) {
            //40 pfeil runter, 38 pfeil rauf, 32 leertaste
            var keycode = evt.which;
            if ($(this).find('.active').length == 0) return;

            if (keycode == 40) {
                var obj = $(this).find('.active');
                var nextObj = obj.next();
                while (nextObj.hasClass('hidden') || nextObj.hasClass('searchHidden') || nextObj.hasClass('locked')) {
                    nextObj = nextObj.next();
                }

                if (nextObj.length != 0) {
                    obj.removeClass('active');
                    nextObj.addClass('active')
                }
                evt.preventDefault();
            } else if (keycode == 38) {
                var obj = $(this).find('.active');
                var prevObj = obj.prev();
                while (prevObj.hasClass('hidden') || prevObj.hasClass('searchHidden') || prevObj.hasClass('locked')) {
                    prevObj = prevObj.prev();
                }
                if (prevObj.length != 0) {
                    obj.removeClass('active');
                    prevObj.addClass('active')
                }
                evt.preventDefault();
            } else if (keycode == 32) {
                var obj = $(this).find('.active');

                if (obj.hasClass('checked')) domObj.setUnChecked(obj.data('id'), true);
                else domObj.setChecked(obj.data('id'), true);
                $('div#' + _objectName).trigger('change');
                evt.preventDefault();
                return;
            }

            var viewportHeight = $(this).height();
            var divScrollTop = $(this).scrollTop();

            var minTop = divScrollTop;
            var maxTop = divScrollTop + viewportHeight;
            var myElement = $(this).find('li.active');

            var elementTop = myElement.position().top + divScrollTop;
            var elementHeight = myElement.height();

            if (elementTop >= (maxTop - 20)) $(this).scrollTop(elementTop - viewportHeight + elementHeight);
            else if (elementTop <= (minTop - 5)) $(this).scrollTop(elementTop);
        });
    };

    _init();

    return domObj;
};


/**
 * Wird von FormFieldReference und FormFieldTextareaHTML verwendet
 * @param stateparam
 */
function openCmshxMedienpool(root, stateparam) {
    window.open(root + "cmshx/cmshx_frontend/medienpool/index.php?sp=" + stateparam,
        "LINK",
        "width=810,resizable=yes,height=710,scrollbars=yes,statusbar=yes,toolbars=no,dependent=yes").focus();
}


function setHXFormFieldTextareaHTMLLink(fieldname, link) {
    var obj = $('div.' + fieldname + 'Container');
    obj.find('.jqte_editor').trigger('openlinkarea');
    obj.find('.jqte_hxselect').first().val(0);
    obj.find('.jqte_linkinput').first().val(link);
    obj.find('.jqte_linkbutton').first().trigger('click');
}

function clearHXFormFieldReference(fieldname) {
    //values setzen loeschen
    $('#refPool_' + fieldname + ' div').first().html('');
    $('input[name=refPool_' + fieldname + '_id').first().val('');
    $('input[name=refPool_' + fieldname + '_type').first().val('');

    //deleteicon ausblenden
    $('#refPool_' + fieldname + ' #deleteIcon').css({display: 'none'});

}

function setHXFormFieldReference(fieldname, id, type, name) {
    //values setzen
    var div = $('#refPool_' + fieldname + ' div').first();
    div.html(hx.guiSetEllipsis(name, div.width(), 'center'));

    $('input[name=refPool_' + fieldname + '_id').first().val(id);
    $('input[name=refPool_' + fieldname + '_type').first().val(type);

    //deleteicon anzeigen
    $('#refPool_' + fieldname + ' #deleteIcon').css({display: ''});
}


function hasHxFormFieldMultiUploadBigFields() {
    return ($('.hx-formfield-multiupload').length > 0);
}

function hasNewUploads() {
    return ($('.hx-formfield-multiupload').find('ul.js-new-files li').length > 0);
}

function startUploadHxFormFieldMultiUploadBig() {
    hx.guiOverlayOpen($('#hx-formfield-multiupload-overlay').get(0));
    $('[name="start-upload"]').trigger('startupload');
    $(document).on("keydown mousedown", function (evt) {
        evt.preventDefault();
    });
}

$(document).ready(function () {
    $(document).on("click", ".formsubmit", function () {
        var action = 1;
        if ($(this).hasClass("formbuttonApply")) action = 2;
        $("form[name=f]").data('action', action);

        if (beforesubmit()) {
            if ($(this).hasClass("formbuttonApply")) {
                $('<input>').attr({
                    type: "hidden",
                    name: "formbutton_apply",
                    value: "pressed"
                }).appendTo("form[name=f]");
            }
            $(".formbutton").prop("disabled", true);
            $("form[name=f]").submit();
        }
    });
});




/************************
 * -------- START --------
 * Helper function fuer HXFormFieldNumber.php
 *************************/
function checkHxFormFieldNumberFormatAndLimit(fieldname) {
    var obj = $("[name='" + fieldname + "']");
    var value = obj.val();

    var labelname = obj.data("name");
    var minValue = obj.data("min");
    var maxValue = obj.data("max");
    var decimalplaces = obj.data("decimalplaces");

    var formatMsg = hx.replace(obj.data("dict_formatmsg"), "#name#", labelname);
    var limitMsg = hx.replace(obj.data("dict_limitmsg"), "#name#", labelname);
    limitMsg = hx.replace(limitMsg, "#limits#", hx.formatPreis(minValue, -1) + " ... " + hx.formatPreis(maxValue, -1));

    if (check_number_field(fieldname, formatMsg, limitMsg, minValue, maxValue) == false) return false;
    obj.val(value);

    if (decimalplaces >= 0) {
        var anzahlNachkommaMsg = hx.replace(obj.data("dict_anzahl_nachkomma"), "#name#", labelname);
        anzahlNachkommaMsg = hx.replace(anzahlNachkommaMsg, "#kommastellen#", decimalplaces);
        var t = value.split(',');
        if (t[1]) {
            if (('' + t[1]).toLowerCase().indexOf('e') >= 0) {
                t[1] = '' + t[1];
                t[1] = t[1].toLowerCase();
                t[1] = t[1].split('e')[0];
            }

            if (t[1].length > decimalplaces) {
                alert(anzahlNachkommaMsg);
                obj.focus().select();
                return false;
            }
        }
    }

    return true;
}

/************************
 * -------- ENDE --------
 * Helper function fuer HXFormFieldNumber.php
 *************************/
function hx() {
}

/**
 * Wie die PHP-Funktion rand()
 * Gibt eine zuf�llige Zahl (int) zur�ck zwischen "min" und "max"
 *
 * @param {number} min    - kleinster zul�ssige Wert
 * @param {number} max    - gr��ter zul�ssiger Wert
 *
 * @return {number}
 */
hx.rand = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

hx.ceil = function (value) {
    return Math.ceil(value);
};

hx.floor = function (value) {
    return Math.floor(value);
};

/**
 * Wie die PHP-Funktion round()
 * Kaufm�nnisches Runden mit Angabe auf wie viel Nachkommastellen
 *
 * @param {number}        value
 * @param {number}        precision - Anzahl Nachkommastellen
 *
 * @return {number}
 */
hx.round = function (value, precision) {
    if (!precision) precision = 0;
    if (precision < 0) return value;
    value = value * Math.pow(10, precision);
    value = Math.floor(value + 0.5);
    value = value / Math.pow(10, precision);
    return value;
};

hx.strtoupper = function (str) {
    var t = str + "";
    return t.toUpperCase();
};

hx.strtolower = function (str) {
    var t = str + "";
    return t.toLowerCase();
};

hx.replace = function (textstr, findstr, repstr) {
    var t = '', f = '', r = '', n = 0, npos = 0;
    t = '' + textstr;
    f = '' + findstr;
    r = '' + repstr;
    if ((f != "") && (f != r)) {
        npos = t.indexOf(f);
        while (npos >= 0) {
            t = t.substr(0, npos) + r + t.substr(npos + f.length);
            n = npos + r.length;
            npos = t.substr(n).indexOf(f);
            if (npos >= 0) npos = npos + n;
        }
    }
    return t;
};

hx.is_array = function (value) {
    if (Object.prototype.toString.call(value) === '[object Array]') return true;

    return false;
};

hx.is_object = function (value) {
    if (Object.prototype.toString.call(value) === '[object Object]') return true;

    return false;
};

hx.is_string = function (value) {
    if (typeof value === "string") return true;

    return false;
};

hx.is_numeric = function (value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

/*
 * Z�hlt Anzahl eintr�ge eines Arrays/Objects. Liefert -1 wenn kein Array/Object �bergeben wurde
 *
 * @param obj		Array/Object
 *
 * @return {number}
 */
hx.count = function (obj) {
    var size;
    try {
        size = obj.length;
        if (hx.is_numeric(size)) return obj.length;
    } catch (e) {
    }

    try {
        size = Object.keys(obj).length;
        if (hx.is_numeric(size)) return size;
    } catch (e) {
    }

    try {
        size = 0;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        if (hx.is_numeric(size)) return size;
    } catch (e) {
    }

    return -1;
};


/**
 * Pr�ft ob Wert (zB aus Inputfeld) ein g�ltiger INT Wert ist
 *
 * @param str Deutsch formatierte Zahl
 * @returns {boolean}
 */
hx.text_isint = function (str) {
    if (!str) str = "";

    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");

    if (hx.is_numeric(t) && hx._has_decimalplaces(t) == false) return true;

    return false;
};

/**
 * Pr�ft ob Wert (zB aus Inputfeld) ein g�ltiger FLOAT Wert ist
 *
 * @param str Deutsch formatierte Zahl
 * @returns {boolean}
 */
hx.text_isfloat = function (str) {
    if (!str) str = "";

    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");

    if (hx.is_numeric(t) && hx._has_decimalplaces(t) == true) return true;
    return false;
};

/**
 * Wandelt Wert aus String (zB aus Inputfeld) in  FLOAT Wert um wenn g�ltig, sonst NULL
 *
 * @param str
 * @returns {*}
 */
hx.text2float = function (str) {
    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");
    if (!hx.is_numeric(t)) return null;
    return hx.cfloat(t);
};

/**
 * Wandelt Wert aus String (zB aus Inputfeld) in INT Wert um wenn g�ltig, sonst NULL
 *
 * @param {number} str Deutsch formatierte Zahl
 *
 * @return {number}
 */
hx.text2int = function (str) {
    var t = str + "";
    t = hx.replace(t, ".", "");
    t = hx.replace(t, ",", ".");
    if (!hx.is_numeric(t)) return null;
    if (hx._has_decimalplaces(t)) return null;
    return hx.cint(t);
};


hx._has_decimalplaces = function (value) {
    value = "" + value;
    if (value.lastIndexOf(".") >= 0 && value.lastIndexOf(".") != value.length - 1) {
        return true;
    }
    return false;
};

/**
 * Wie die PHP-Funktion in_array()
 * Pr�ft ob ein Array/Objekt[Benanntes Array] einen Wert enth�lt
 *
 * @param {array}        arr        - Array in dem gesucht wird
 * @param {number}        value    - zu suchender Wert
 *
 * @return boolean bool
 */
hx.in_array = function (arr, value) {
    if (arr.length) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] == value) return true;
        }
    } else {
        for (var i in arr) {
            if (arr[i] == value) return true;
        }
    }
    return false;
};


/**
 * Entfernt doppelte Werte aus Array (wird sortiert zur�ckgegeben)
 *
 * @param {number}        arr        - Array in dem gesucht wird
 *
 * @return Array array
 */
hx.array_unique = function (arr) {
    var uniquekeys = new Object();
    var erg = new Array();
    for (var i = 0; i < arr.length; i++) {
        uniquekeys[arr[i]] = 1;
    }
    for (var i in uniquekeys) {
        erg.push(i);
    }
    return erg;
};


/**
 * Pr�ft ob der erste Wert in den nachfolgenden Werten vorkommt
 *
 * @param {string} needle
 * @param {string} haystacks1
 * @param {string} haystack2
 * @returns {boolean}
 */
hx.value_in = function (needle, haystacks1, haystack2) {
    for (var i = 1; i < arguments.length; i++) {
        if (needle == arguments[i]) return true;
    }
    return false;
};


/**
 * Wie ein (int)-Cast in PHP
 * G�ltigen Werten werden die Nachkommastellen abgeschnitten
 * und ung�ltige Werte werden zum Wert 0
 *
 * @param value
 * @returns {number}
 */
hx.cint = function (value) {
    if (typeof value == 'undefined') return 0;
    if (value === null) return 0;
    if (hx.is_array(value) || hx.is_object(value)) {
        if (hx.count(value) > 0) {
            return 1;
        }
        return 0;
    }
    if (hx.is_string(value)) {
        if (value.charAt(0) == '.') value = "0" + value;
        if (value.charAt(0) == '+') value = value.substr(1);
        for (var i = 0; i < value.length; i++) {
            if (value.charAt(i).search(/[0-9\.\-]/) < 0) {
                value = value.substr(0, i);
                break;
            }
        }
    } else if (value === true || value === false) {
        return Math.abs(value);
    }
    if (hx.is_numeric(value)) {
        return parseInt(value, 10);
    } else {
        return 0;
    }
};

/**
 * Wie ein (float)-Cast in PHP
 * Ung�ltige werte werden zum Wert 0
 *
 * @param value
 * @returns {number}
 */
hx.cfloat = function (value) {
    if (typeof value == 'undefined') return 0;
    if (value === null) return 0;
    if (hx.is_array(value) || hx.is_object(value)) {
        if (hx.count(value) > 0) {
            return 1;
        }
        return 0;
    }
    if (hx.is_string(value)) {
        if (value.charAt(0) == '+') value = value.substr(1);
        for (var i = 0; i < value.length; i++) {
            if (value.charAt(i).search(/[0-9\.\-]/) < 0) {
                value = value.substr(0, i);
                break;
            }
        }
    } else if (value === true || value === false) {
        return Math.abs(value);
    }
    if (hx.is_numeric(value)) {
        return parseFloat(value);
    } else {
        return 0;
    }
};

hx.explode = function (delim, str) {
    if (!delim) delim = "";
    if (!str) str = "";
    var d = delim + "";
    var s = str + "";
    return s.split(d);
};

hx.implode = function (glue, arr) {
    if (!glue) glue = "";
    if (!hx.is_array(arr)) arr = new Array();
    var g = glue + "";
    return arr.join(g);
};

hx.substr = function (str, start, length) {
    if (!str) str = "";
    var t = str + "";
    if (length) {
        return t.substr(start, length);
    } else {
        return t.substr(start);
    }
};

/**
 * Strings die l�nger sind als "length" werden abgeschnitten
 * und es wird ein "..." angeh�ngt
 *
 * @param str
 * @param length
 * @returns {string}
 */
hx.leftcut = function (str, length) {
    if (!str) str = "";
    var t = str + "";
    if (t.length > length) {
        return t.substr(0, length) + "...";
    } else {
        return t;
    }
};

/**
 * Strings die l�nger sind als "length" werden abgeschnitten
 * und es wird ein "..." angeh�ngt. Der ganze String wird als Tooltip ausgegeben.
 *
 * @param {string} str
 * @param {number} length
 *
 * @return {string}
 */
hx.leftcuttitle = function (str, length) {
    if (!str) str = "";
    var s = str + "";
    if (s.length > length) {
        var t = document.createElement("span");
        t.title = s;
        t.innerHTML = s.substr(0, length);
        return t;
    } else {
        return s;
    }
};

/**
 * White-Spaces werden auf der linken und rechten Seite entfernt.
 * Wenn der Browser "trim" unterst�tzt wird seine eigene Funktion verwendet.
 * Ansonsten wird eigene Implementierung verwendet.
 *
 * @param {string} str                 der zu trimmende String
 * @param {string} trimchar         Zeichen welche vom Trim gesucht werden und entfernt werden (optional)
 *
 * @return {string}
 */
hx.trim = function (str, trimchar) {
    if (!str) str = "";
    var t = str + "";
    if (trimchar) {
        // Schl�sselzeichen escapen
        trimchar = hx.replace(trimchar, "[", "\\[");
        trimchar = hx.replace(trimchar, "]", "\\]");
        trimchar = hx.replace(trimchar, "(", "\\(");
        trimchar = hx.replace(trimchar, ")", "\\)");
        trimchar = hx.replace(trimchar, "{", "\\{");
        trimchar = hx.replace(trimchar, "}", "\\}");
        trimchar = hx.replace(trimchar, "^", "\\^");
        trimchar = hx.replace(trimchar, "$", "\\$");
        trimchar = hx.replace(trimchar, ".", "\\.");
        while (t.charAt(0).search(new RegExp('^[' + trimchar + ']+', 'g')) >= 0) {
            t = t.substr(1, t.length - 1);
        }
        while (t.charAt(t.length - 1).search(new RegExp('^[' + trimchar + ']+', 'g')) >= 0) {
            t = t.substr(0, t.length - 1);
        }
        return t;
    } else if (!String.prototype.trim) {
        return t.replace(/^\s+|\s+$/g, '');
    } else {
        return t.trim();
    }
};


/**
 * Alle Tags im String werden entfernt.
 *
 * @param {string}        str
 *
 * @return {string}
 */
hx.strip_tags = function (str) {
    if (!str) str = "";
    str = str + "";
    return str.replace(/(<([^>]+)>)/gi, "");
};


/**
 * Pr�fen ob String mit Prefix beginnt
 *
 * @param str
 * @param prefix
 * @returns {boolean}
 */
hx.startsWith = function (str, prefix) {
    if (str.indexOf(prefix) == 0) return true;
    return false;
};

/**
 * Pr�fen ob String mit Suffix endet
 *
 * @param str
 * @param suffix
 * @returns {boolean}
 */
hx.endsWith = function (str, suffix) {
    return str.match(suffix + "$") == suffix;
};


/**
 * Prueft auf gueltige Emailadresse
 * Seit 24.11.2017 neue Logik mit erweiterter Pruefung:
 *  - Generell erlaubte Zeichen sind A-Z a-z 0-9 - _ + . @
 *  - Keine Umlaute
 *  - Kein scharfes S
 *  - Keine Leerzeichen
 *  - Erstes und letztes Zeichen muessen gueltig sein
 *  - Kein ".."
 *
 * @param {string} email
 *
 * @return {boolean}
 */
hx.isValidEmailAddress = function (email) {
    if (!email) return false;

    // Leerzeichen sind nicht erlaubt
    //if(email.indexOf(" ") > -1) return false;

    // Umlaute + scharfes S sind nicht erlaubt
    // var regex1 = new RegExp("\u00c4|\u00e4|\u00d6|\u00f6|\u00dc|\u00fc|\u00df");
    // if(email.match(regex1) !== null) return false;

    // Erlaubt sind nur A-Z a-z 0-9 - _ + . @ -> keine Umlaute, Leerzeichen und weitere Sonderzeichen
    var regex3 = new RegExp("[^A-Za-z0-9\-\_\+\.\&@]");
    if (email.match(regex3) !== null) return false;

    // Klammeraffe muss genau ein Mal vorhanden sein
    var atCount = 0;
    for (var n = 0; n < email.length; n++) if (email.charAt(n) == '@') atCount++;
    if (atCount != 1) return false;

    var atPos = email.indexOf('@');

    // "Local part" darf max. 64 Zeichen haben
    if (atPos > 63) return false;

    // Nach dem Klammeraffen muss mind. ein Punkt sein
    var count = 0;
    for (n = atPos; n < email.length - 1; n++) if (email.charAt(n) == '.') count++;
    if (count < 1) return false;

    // Email muss mit gueltigen Zeichen beginnen und aufhoeren und die Domaene muss alphanumerisch beginnen
    var regex_alphanumeric = new RegExp("[A-Za-z0-9]");

    var firstChar = email.charAt(0);
    if (firstChar.match(new RegExp("[A-Za-z0-9\-\_\+]")) == null) return false;

    var beforeAtChar = email.charAt(atPos - 1);
    if (beforeAtChar.match(regex_alphanumeric) == null) return false;

    var afterAtChar = email.charAt(atPos + 1);
    if (afterAtChar.match(regex_alphanumeric) == null) return false;

    var lastChar = email.charAt(email.length - 1);
    if (lastChar.match(regex_alphanumeric) == null) return false;

    // Es duerfen keine zwei Punkte direkt nach einander vorkommen
    if (email.match(new RegExp("\\.\\.")) !== null) return false;

    return true;
};

/**
 * Pr�ft auf g�ltige URL
 * allowedProtocols sind standardm��ig "http","https","ftp" und "ftps"
 * kann ge�ndert werden mit �bergebenem array
 *
 * @param url
 * @param allowedProtocols -  Array
 * @returns {boolean}
 */
hx.isValidUrl = function (url, allowedProtocols) {
    // geaendert 01.12.2017 SF
    if (url == "") return false;

    if (!allowedProtocols) allowedProtocols = new Array("http", "https", "ftp", "ftps");
    //array und implode
    if (Array.isArray(allowedProtocols)) allowedProtocols = hx.implode("|", allowedProtocols);

    //  ('^((https|http|ftp|ftps):\\/\\/)'+ // protocol
    // '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    // '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    // '(\\:\\d+)?(\\/{1}[-a-z\\d%_.~+]*)*'+ // port and path
    // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

    var url_pattern = new RegExp('^(((' + allowedProtocols + '):\\/\\/)((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/{1}[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$)|javascript:\d*', 'i');
    if (!url_pattern.test(url)) return false;

    return true;

    // url = "" + url;
    // if(!allowedProtocols){
    //     allowedProtocols = new Array("http", "https", "ftp", "ftps");
    // }
    // var protocolValid = false;
    // hx.foreach(allowedProtocols, function(value){
    //     if(url.toLowerCase().indexOf(value.toLowerCase() + "://") >= 0) protocolValid = true;
    // });
    //
    //
    //
    // if(!protocolValid) return false;
    // var parser = document.createElement('a');
    // parser.href = url;
    // if(parser.hostname.indexOf("%") >= 0 || parser.hostname.length == 0) return false;
    // // Kein Port oder g�ltiger Port
    // if(parser.port.length > 0 && parser.port == 0) return false;
    // var host = parser.hostname.split(".");
    // // Keine Spezialzeichen in der Endung
    // if(host[host.length - 1].replace(/[a-zA-Z]/ig, "").length > 0) return false;
    // // L�nge der Endung mindestens 2
    // //if(host[host.length-1].length<2) return false;
    // return true;
};

/**
 * Wie PHP-Funktion print_r
 * Formatierte Ausgabe eines Arrays/Objekts
 *
 * @param {Array}            arr
 * @param {number}        level (automatisch)
 *
 * @return {string}
 */
hx.print_r = function (arr, level) {
    var dumped_text = "";
    if (!level) level = 0;

    if (level > 10) return "Max 6 levels werden aufgelistet";

    //The padding given at the beginning of the line.
    var level_padding = "";
    var bracket_level_padding = "";

    for (var j = 0; j < level + 1; j++) level_padding += "    ";
    for (var b = 0; b < level; b++) bracket_level_padding += "    ";

    if (typeof(arr) == 'object') { //Array/Hashes/Objects
        dumped_text += "Array\n";
        dumped_text += bracket_level_padding + "(\n";
        for (var item in arr) {

            var value = arr[item];

            if (typeof(value) == 'object') { //If it is an array,
                dumped_text += level_padding + "[" + item + "] => ";
                dumped_text += hx.print_r(value, level + 2);
            } else {
                dumped_text += level_padding + "[" + item + "] => " + value + "\n";
            }

        }
        dumped_text += bracket_level_padding + ")\n\n";
    } else { //Stings/Chars/Numbers etc.
        dumped_text = "===>" + arr + "<===(" + typeof(arr) + ")";
    }

    return dumped_text;
};

hx.isValidFilename = function (filename) {
    if (!filename) filename = "";
    filename = filename + "";
    if (filename == "") return false;
    var valid = "abcdefghijklmnopqrstuvwxyz���ABCDEFGHIJKLMNOPQRSTUVWXYZ����0123456789_-.";
    for (var i = 0; i < filename.length; i++) {
        if (valid.indexOf(filename[i]) < 0) return false;
    }
    return true;
};

hx.makeValidFilename = function (s, spacesAs) {
    if (!s) s = "";
    s = s + "";
    if (s == "") return "noname";
    if (!spacesAs) spacesAs = "_";
    s = hx.replace(s, " ", spacesAs);
    var valid = "abcdefghijklmnopqrstuvwxyz���ABCDEFGHIJKLMNOPQRSTUVWXYZ����0123456789_-.";
    var erg = "";
    for (var n = 0; n < s.length; n++) {
        var c = hx.substr(s, n, 1);
        if (valid.indexOf(c) < 0) {
        } else {
            erg = erg + c;
        }
    }
    return erg;

};

/**
 * Gibt in einem Pfad den obersten Ordner/die oberste Datei aus.
 * Wenn "suffix" mit angegeben wird, wird es am Pfadende gesucht und gel�scht.
 *
 * @param {string} str        - Pfad
 * @param {string} suffix    - Suffix das am Pfadende gesucht und entfernt wird
 *
 * @return {string}
 */
hx.basename = function (str, suffix) {
    if (!str) str = "";
    str = str + "";
    str = hx.replace(str, "\\", "/");
    var lastslash = str.lastIndexOf("/");
    if (lastslash == str.length - 1) {
        str = hx.substr(str, 0, str.length - 1);
        lastslash = str.lastIndexOf("/");
    }
    if (suffix) {
        var suffixfound = true;
        for (var i = 0; i < suffix.length; i++) {
            if (suffix[i] != str[str.length - suffix.length + i]) {
                suffixfound = false;
            }
        }
        if (suffixfound) {
            str = hx.substr(str, 0, str.length - suffix.length);
        }
    }
    if (lastslash > 0) {
        return hx.substr(str, lastslash + 1, str.length - 1);
    }
    return hx.replace(str, "/", "");
};


/**
 * Gibt einen Pfad ohne Dateiname aus
 *
 * @param {string} str Pfad
 *
 * @return {string}
 */
hx.dirname = function (str) {
    if (!str) str = "";
    str = str + "";
    str = hx.replace(str, "\\", "/");
    if (str.lastIndexOf("/") == -1) return ".";
    str = hx.substr(str, 0, str.lastIndexOf("/") + 1);
    if (str.length > 1) str = str.replace(/[\/]+$/, "");
    str = str.replace(/^[\.]+$/, ".");
    return str;
};


/**
 * GET-Parameter auslesen aus URL (zB document.location.href)
 *
 * @param str  http://www.hannex.at/index.php?id=5&seite=start
 * @returns {Object} Benanntes Array mit allen Parametern
 */
hx.urlParams2Array = function (str) {
    str = hx.explode('#', str)[0];
    str = hx.basename(str);
    if (str.indexOf('?') < 0) return new Object();
    str = str.substring(str.indexOf('?') + 1);
    var t = str.split('&');
    var erg = new Object();
    for (var i in t) {
        var t2 = t[i].split("=");
        if (t2[1]) erg[t2[0]] = decodeURIComponent(t2[1]);
    }
    return erg;
};


/**
 * Erstellt ein Unix Timestamp (in sekunden) aus den angegebenen Parametern. (00:00:00 Uhr)
 *
 * @param {number} jahr
 * @param {number} monat
 * @param {number} tag
 *
 * @return {number}
 */
hx.dateSerial = function (jahr, monat, tag) {
    var d = new Date(0);
    monat--;
    d.setHours(0, 0, 0, 0);
    d.setFullYear(jahr);
    d.setMonth(monat);
    d.setDate(tag);
    return d.getTime();
};
/**
 * Erstellt ein Date-Objekt aus den angegebenen Parametern. (01.01.1970)
 *
 * @param {number} stunde
 * @param {number} minute
 * @param {number} sekunde
 *
 * @return {number}
 */
hx.timeSerial = function (stunde, minute, sekunde) {
    var d = new Date(0);
    var offset = d.getTimezoneOffset() / 60;
    if (offset < 0) offset = offset * -1;
    d.setHours(stunde + offset);
    d.setMinutes(minute);
    d.setSeconds(sekunde);
    return d.getTime();
};

/**
 * Erstellt ein Date-Objekt aus den angegebenen Parametern.
 *
 * @param {number} jahr
 * @param {number} monat
 * @param {number} tag
 * @param {number} stunde
 * @param {number} minute
 * @param {number} sekunde
 *
 * @return {number}
 */
hx.dateTimeSerial = function (jahr, monat, tag, stunde, minute, sekunde) {
    var d = new Date();
    monat--;
    d.setHours(0, 0, 0, 0);
    d.setFullYear(jahr);
    d.setMonth(monat);
    d.setDate(tag);
    d.setHours(stunde);
    d.setMinutes(minute);
    d.setSeconds(sekunde);
    return d.getTime();
};

/**
 * Holt Jahreszahl aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.year = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getFullYear();
};


/**
 * Gibt die zweistellige Jahreszahl eines Datums zurueck
 * Funktioniert NUR fuer Jahr >1999
 *
 * @param date
 *
 * @return {number}
 */
hx.yearShort = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    var year = hx.cint(date.getYear());

    if (year > 99) {
        return year - 100;
    } else {
        return year;
    }
};


/**
 * Holt Halbjahr aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.halfYear = function (date) {
    if (!date || date + "" == "") return "";
    return hx.ceil(hx.month(date) / 6);
};

/**
 * Holt Quartal aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.quarterYear = function (date) {
    if (!date || date + "" == "") return "";
    return hx.ceil(hx.month(date) / 3);
};

/**
 * Holt Monat (Nr) aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.month = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getMonth() + 1;
};

/**
 * Holt Tag (Nr) aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.day = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getDate();
};

/**
 * Gibt Mo, Di etc. zurueck
 *
 * @param {number} integer
 */
hx.dayShort = function (integer) {
    if (!hx.is_numeric(integer)) return "";

    var date = new Date(integer);
    var weekday = date.getDay();

    if (weekday == 0) return "So";
    if (weekday == 1) return "Mo";
    if (weekday == 2) return "Di";
    if (weekday == 3) return "Mi";
    if (weekday == 4) return "Do";
    if (weekday == 5) return "Fr";
    if (weekday == 6) return "Sa";
};

/**
 * Holt Stunde aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.hour = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getHours();
};

/**
 * Holt Minute aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.minute = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getMinutes();
};

/**
 * Holt Sekunde aus einem Date-Objekt
 *
 * @param date
 *
 * @return {number}
 */
hx.second = function (date) {
    if (!date || date + "" == "") return "";
    date = new Date(date);
    return date.getSeconds();
};

/**
 * Erstellt ein Datums-Objekt mit dem aktuellen Datum.
 *
 * @param {boolean} withCurrTime Setzt aktuelle Uhrzeit auf jetzt oder auf 00:00:00
 *
 * @return {number}
 */
hx.now = function (withCurrTime) {
    var d = new Date();
    if (withCurrTime === false) {
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
    }
    d.setMilliseconds(0);
    return d.getTime();
};

/**
 * Addiert Parameter zu Datum
 *
 * @param date
 * @param {number} y zu addierende Jahre
 * @param {number} m zu addierende Monate
 * @param {number} d zu addierende Tage
 * @param {number} h zu addierende Stunden
 * @param {number} n zu addierende Minuten
 *
 *
 * @return {number}
 */
hx.dateTimeAdd = function (date, y, m, d, h, n) {
    date = new Date(date);
    if (y) date.setFullYear(date.getFullYear() + y);
    if (m) date.setMonth(date.getMonth() + m);
    if (d) date.setDate(date.getDate() + d);
    if (h) date.setHours(date.getHours() + h);
    if (n) date.setMinutes(date.getMinutes() + n);
    return date.getTime();
};

/**
 * Addiert Parameter zu Datum
 *
 * @param date
 * @param {number} y zu addierende Jahre
 * @param {number} m zu addierende Monate
 * @param {number} d zu addierende Tage
 *
 *
 * @return {number}
 */
hx.dateAdd = function (date, y, m, d) {
    date = new Date(date);
    if (y) date.setFullYear(date.getFullYear() + y);
    if (m) date.setMonth(date.getMonth() + m);
    if (d) date.setDate(date.getDate() + d);
    return date.getTime();
};

/**
 * Zeitlichen Unterschied zwischen zwei Datums-Objekten ausrechnen.
 * date2-date1
 *
 * @param {string} interval    - "w" in Wochen, "d" in Tagen, "h" in Stunden, "n" in Minuten, "s" in Sekunden
 * @param date1
 * @param date2
 *
 *
 * @return {number}
 */
hx.dateDiff = function (interval, date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    var timedifference = date2.getTime() - date1.getTime();
    var corr = Math.abs(date2.getTimezoneOffset()) - Math.abs(date1.getTimezoneOffset());
    corr = corr * 60 * 1000;
    timedifference += corr;

    switch (interval) {
        case "w":
            return hx.cint(timedifference / 604800000);
        case "d":
            return hx.cint(timedifference / 86400000);
        case "h":
            return hx.cint(timedifference / 3600000);
        case "n":
            return hx.cint(timedifference / 60000);
        case "s":
            return hx.cint(timedifference / 1000);
    }
};

/**
 * Datums-Objekt in hh:mm formatieren
 *
 * @param date
 *
 * @return {string}
 */
hx.formatTime = function (date) {
    if (hx.cint(date) == 0) return "";
    date = new Date(date);
    return hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes());
};

/**
 * Datums-Objekt in tt.mm.yyyy formatieren
 *
 * @param date
 *
 * @return {string}
 */
hx.formatDate = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear();
};

hx.formatDateShort = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear().toString().substr(2, 2);
};

/**
 * Datums-Objekt in tt.mm.yyyy hh:mm formatieren
 *
 * @param date
 *
 * @return {string}
 */
hx.formatDateTime = function (date) {
    if (!hx.is_numeric(date)) return "";
    date = new Date(date);
    return hx.format00(date.getDate()) + "." + hx.format00(date.getMonth() + 1) + "." + date.getFullYear() + " " + hx.format00(date.getHours()) + ":" + hx.format00(date.getMinutes());
};

/**
 * Kalenderwoche eines Datums-Objekts ausgeben
 *
 * @param date
 *
 * @return {number}
 */
hx.date2kw = function (date) {
    if (hx.cint(date) == 0) return;
    date = new Date(date);
    var jahr = date.getFullYear();
    var jh = jahr + 1;
    var kalwo = hx._kaldiff(date, jh);
    while (kalwo < 1) {
        jh--;
        kalwo = hx._kaldiff(date, jh);
    }
    return kalwo;
};

hx._kaldiff = function (datum, jahr) {
    var d4j = new Date(jahr, 0, 4);
    var wt4j = (d4j.getDay() + 6) % 7;
    return Math.floor(1.05 + (datum.getTime() - d4j.getTime()) / 6048e5 + wt4j / 7);
};

/**
 * Ersten Wochentag der Kombination KW+Jahr herausfinden
 *
 * @param {number} kw
 * @param {number} jahr
 *
 * @returns {number}
 */
hx.kw2date = function (kw, jahr) {
    var datum = hx.text2date("01.07." + jahr);
    while (hx.date2kw(datum) > kw && hx.year(datum) == jahr) datum = hx.dateAdd(datum, 0, 0, -1);
    while (hx.date2kw(datum) < kw && hx.year(datum) == jahr) datum = hx.dateAdd(datum, 0, 0, 1);

    // auf Sonntag der vorherigen KW "zurueckfahren"
    while (hx.date2kw(datum) == kw) datum = hx.dateAdd(datum, 0, 0, -1);

    return hx.dateAdd(datum, 0, 0, 1);
};

hx.format00 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 2) t = "0" + t;
    if (t.length < 2) t = "0" + t;
    return t;
};

hx.format000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 3) t = "0" + t;
    if (t.length < 3) t = "0" + t;
    if (t.length < 3) t = "0" + t;
    return t;
};

hx.format0000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    if (t.length < 4) t = "0" + t;
    return t;
};

hx.format00000 = function (s) {
    if (!s) s = "";
    var t = "" + s;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    if (t.length < 5) t = "0" + t;
    return t;
};


/**
 * Ein deutsch formatiertes Datum aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} str
 *
 * @return {string}
 */
hx.text2date = function (str) {
    if (!str) str = "";
    str = str + "";
    var t1 = str;
    var t2 = t1;
    var d, datum, dat_donn, dat_mon;
    var t = 0, m = 0, j = 0;
    var tx = 0, mx = 0, jx = 0;
    if (t1 == "") {
        return null;
    }
    var tmp = t1.split(".");
    if (tmp.length != 3 && tmp.length != 1) {
        return null;
    }
    if (tmp.length == 1) {
        if (t2.length == 8) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 4);
        } else if (t2.length == 6) {
            tx = t2.substr(0, 2);
            mx = t2.substr(2, 2);
            jx = t2.substr(4, 2);
        } else if (t2.length == 4) {
            kwx = t2.substr(0, 2);
            jx = t2.substr(2, 2);
            j = parseInt(jx, 10);
            kw = parseInt(kwx, 10);
            if (isNaN(kw) || isNaN(j)) {
                return null;
            }
            kw = kw - 1;
            j = j + 2000;
            // ersten Donnerstag des Jahres:
            dat_donn = new Date(j, 0, 1);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 2);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 3);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 4);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 5);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 6);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 7);
            if (dat_donn.getDay() != 4) dat_donn = new Date(j, 0, 8);

            // Jetzt Montag suchen
            dat_mon = dat_donn;
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);
            if (dat_mon.getDay() != 1) dat_mon = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() - 1);

            datum = new Date(dat_mon.getFullYear(), dat_mon.getMonth(), dat_mon.getDate() + (kw * 7));
            tx = datum.getDate();
            mx = datum.getMonth() + 1;
            jx = datum.getFullYear();
        } else {
            return null;
        }
        t = parseInt(tx, 10);
        m = parseInt(mx, 10);
        j = parseInt(jx, 10);
    }
    if (tmp.length == 3) {
        t = parseInt(tmp[0], 10);
        m = parseInt(tmp[1], 10);
        j = parseInt(tmp[2], 10);
    }
    if (isNaN(t) || isNaN(m) || isNaN(j)) {
        return null;
    }
    if (t < 1 || t > 31) {
        return null;
    }
    if (m < 1 || m > 12) {
        return null;
    }
    if (j < 70 && j >= 0) {
        j = j + 2000;
    }
    if (j >= 70 && j < 100) {
        j = j + 1900;
    }
    if (j < 1900 || j > 2100) {
        return null;
    }
    d = new Date(j, m - 1, t);

    return d.getTime();
};

/**
 * Eine deutsch formatierte Uhrzeit aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} txt
 *
 * @return {string}
 */
hx.text2time = function (txt) {
    if (txt.replace(/[0-9]{1,2}:?[0-9]{1,2}:?[0-9]{0,2}/, "") != "") return ""; // Keine g�ltige Eingabe
    if (txt.indexOf(":") < 0) {
        txt = txt.substr(0, 2) + ":" + txt.substr(2, 2) + ":" + txt.substr(4, 2);
    }
    var t = txt.split(":");
    for (var i in t) t[i] = hx.cint(t[i]);
    if (t.length < 3) {
        t.push(0);
        t.push(0);

    }
    if (t[0] >= 0 && t[0] < 24 && t[1] >= 0 && t[1] < 60 && t[2] >= 0 && t[2] < 60) {
        return hx.dateTimeSerial(1970, 1, 1, t[0], t[1], t[2]);
    }
    return "";
};

/**
 * Ein deutsch formatiertes Datum+Uhrzeit aus einem String
 * auslesen und in ein Datums-Objekt umwandeln
 *
 * @param {string} str
 *
 * @return {string}
 */
hx.text2datetime = function (str) {
    var txt = hx.replace(str, " ", ".");
    txt = hx.replace(txt, ":", ".");
    var a = hx.explode(".", txt);
    var t = hx.cint(a[0]);
    var m = hx.cint(a[1]);
    var j = hx.cint(a[2]);
    var h = hx.cint(a[3]);
    var n = hx.cint(a[4]);
    var erg;
    if (t > 0 && t < 32 && m > 0 && m < 13 && j > 0 && j < 3000) {
        if (h >= 0 && h <= 23 && n >= 0 && n <= 59) {
            erg = hx.dateTimeAdd(hx.dateSerial(j, m, t), 0, 0, 0, h, n);
        } else {
            erg = hx.dateSerial(j, m, t);
        }
        return erg;
    }
    return "";
};

/**
 * HTTP-GET-Request ausf�hren.
 * Wenn eine Callback-Funktion angegeben ist, wird der Request asynchron
 *
 * @param {string} url                - Ziel
 * @param {string} callbackfunction    - Funktionsname
 *
 * @return {string}
 */
hx.httpRequest = function (url, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");

            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) {
        http_request.onreadystatechange =
            function () {
                if (http_request.readyState == 4) {
                    callbackfunction(http_request.responseText);
                }
            };
    }
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('GET', url, true);
            http_request.send(null);
        } else {
            http_request.open('GET', url, false);
            http_request.send(null);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
};

/**
 * HTTP-POST-Request ausf�hren.
 * Wenn eine Callback-Funktion angegeben ist, wird der Request asynchron
 *
 * @param {string} url                - Ziel
 * @param {string} postdata            - Parameter f�r den Request
 * @param {string} callbackfunction    - Funktionsname
 *
 * @return {string|boolean}
 */
hx.httpPostRequest = function (url, postdata, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) {
        http_request.onreadystatechange =
            function () {
                if (http_request.readyState == 4) {
                    callbackfunction(http_request.responseText);
                }
            };
    }
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('POST', url, true);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
        } else {
            http_request.open('POST', url, false);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
};

/**
 * Escape eines Strings als URL-�bergabe-Parameter-Wert
 *
 * @param text
 * @returns {*}
 */
hx.escape = function (text) {
    if (hx.isUTF8()) {
        text = encodeURIComponent(text);
    } else {
        text = escape(text);
        text = text.replace(/[+]/g, '%2b');
    }
    return text;
};

/**
 * Aus einem JS-Objekt einen String erzeugen in der Form:
 * isPOST :  eins=1&zwei=2&drei=3
 * !isPOST:  ?eins=1&zwei=2&drei=3
 *
 * @param obj
 * @param isPOST
 * @returns {string}
 */
hx.createRequestParamString = function (obj, isPOST) {
    var str = "";
    var i = 0;
    var separator = "";
    if (!isPOST) separator = "?";
    hx.foreach(obj, function (elem, key) {
        if (i > 0) separator = "&";
        str += separator + key + "=" + hx.escape(elem);
        i++;
    });
    return str;
};

/**
 *  Ajax-Request-Klasse
 */
hx.httpRequestObject = function () {

    var paramsGET = new Object;
    var paramsPOST = new Object;
    var callbackFunc = null;
    var actionstring = "";
    var response = "";

    this.setCallbackFunc = function (func) {
        callbackFunc = func;
    };

    this.setAction = function (a) {
        actionstring = a;
    };

    this.addGETParam = function (name, value) {
        if (hx.is_array(value) || hx.is_object(value)) {
            addParamArray(paramsGET, name, value);
            return;
        }
        paramsGET[name] = value;
    };

    this.addPOSTParam = function (name, value) {
        if (hx.is_array(value) || hx.is_object(value)) {
            addParamArray(paramsPOST, name, value);
            return;
        }
        paramsPOST[name] = value;
    };

    this.exec = function () {
        var postStr = hx.createRequestParamString(paramsPOST, true);
        var getStr = hx.createRequestParamString(paramsGET);

        if (callbackFunc != null) {
            if (hx.count(paramsPOST) > 0) {
                hx.httpPostRequest(actionstring + getStr, postStr, callbackFunc);
            } else {
                hx.httpRequest(actionstring + getStr, callbackFunc);
            }
        } else {
            if (hx.count(paramsPOST) > 0) {
                response = hx.httpPostRequest(actionstring + getStr, postStr);
            } else {
                response = hx.httpRequest(actionstring + getStr, callbackFunc);
            }
            return response;
        }
    };

    this.getResponse = function () {
        return response;
    };

    var addParamArray = function (params, name, arr, keys) {
        if (!hx.is_array(keys)) keys = new Array();
        if (hx.is_array(arr) || hx.is_object(arr)) {
            hx.foreach(arr, function (v, k) {
                var nextKey = keys.slice();
                nextKey.push(k);
                addParamArray(params, name, v, nextKey);
            });
        } else {
            var key = "";
            hx.foreach(keys, function (k) {
                key += "[" + k + "]";
            });
            if (arr === true || arr === false) arr = hx.cint(arr);
            params[(name + '' + key + '')] = arr;
        }
    };

    return this;
};

/*
 * Aufruf einer beliebigen Funktion die nicht Teil eines Objektes ist
 * Der erste Parameter ist der Funktionsname. Die darauffolgenden Parameter
 * sind die Parameter die an die aufzurufende Funktion �bergeben werden
 *
 * @param {string} name		- Funktionsname
 *
 * @return {string}

 hx.call_user_func = function(name){
 var fn = window[name];
 var args = Array();
 for(var i=1;i<arguments.length;i++) args.push(arguments[i]);
 return fn.apply(this,args);
 };
 */

/**
 * Pr�ft ob die Zeichen die in "str" �bergeben wurden alle in "format" vorkommen.
 * Vordefinierte Formate k�nnen mit format=azAZ (Buchstaben), format=azAZ09 (Alphanumerisch) und format=FILENAME gepr�ft werden.
 *
 * @param {string} str
 * @param {string} format Anzahl der anzuzeigenden Nachkommastellen
 *
 * @return {string|boolean}
 */
hx.isValidString = function (str, format) {
    var a = "";
    if (format == 'azAZ') {
        a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'azAZ09') {
        a = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'FILENAME') {
        a = '0123456789_-.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else {
        a = format;
    }

    var c = '';
    merker = true;
    for (var n = 0; n < str.length; n++) {
        c = str.substr(n, 1);
        if (a.indexOf(c) < 0) {
            merker = false;
            break;
        }
    }
    return merker;
};


/**
 * Formatiert eine Zahl ins Deutsche Format (Beistrich statt Punkt und Punkt als Tausendertrennzeichen)
 *
 * @param {string|int|float} wert
 * @param {string|int} nachkomma            - Anzahl der anzuzeigenden Nachkommastellen
 *
 * @return {string}
 */
hx.formatPreis = function (wert, nachkomma) {
    if (nachkomma == undefined) nachkomma = 2;
    if (nachkomma < 0) { // Wenn nachkomma negativ, dann so viele nachkommastellen wie n�tig
        var t = ("" + wert).split(".");
        if (t[1]) {
            nachkomma = t[1].length;
        } else {
            nachkomma = 0;
        }
    }
    var zahl = hx.cfloat(wert);
    var negativ = false;
    if (zahl < 0) {
        negativ = true;
        zahl = zahl * -1;
    }

    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    if (negativ) erg = "-" + erg;
    return erg;
};

hx.formatByteSize = function (bytes, decimalPlaces) {
    if (typeof bytes !== 'number') return 0;
    if (!decimalPlaces) decimalPlaces = 0;

    if (bytes < 1000) return hx.formatPreis(bytes, decimalPlaces) + " Byte";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " kB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " MB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " GB";
    if ((bytes /= 1024) < 1000) return hx.formatPreis(bytes, decimalPlaces) + " TB";
    return hx.formatPreis((bytes /= 1024), decimalPlaces) + "PB";
};


/**
 * F�llt einen String auf mit Leerzeichen bis er die gew�nschte L�nge erreicht.
 *
 * @param {string} t                - text
 * @param {string} len            - L�nge die erreicht werden soll
 * @param {string} char            - F�llzeichen
 *
 * @return {string}
 */
hx.fixLen = function (t, len, char) {
    if (!t) t = "";
    t = t + "";
    if (!char) char = " ";
    while (t.length < len) {
        t = t + char;
    }
    return t.substr(0, len);
};

/**
 * Testet ob Audio-Objekt existiert. Somit trifft es erst ab IE9 zu
 *
 * @return boolean
 *
 */
hx.isFastBrowser = function () {
    if (typeof(Audio) != 'undefined') {
        return true;
    }
    return false;
};

/**
 * Pr�ft ob Daten UTF8 kodiert sind
 *
 *
 * @returns {boolean}
 */
hx.isUTF8 = function () {
    var c = "";
    if (document.charset) c = document.charset;
    if (document.characterSet) c = document.characterSet;
    return hx.replace(c.toUpperCase(), "-", "") == "UTF8";
};

/**
 * Geht alle Elemente eines Arrays/Objekts durch und f�hrt Funktion auf diese aus
 *
 * z.B. hx.foreach(meinDomArray,function(elem,key){
 *          elem.style.outline='none';
 *      });
 *
 * In der �bergebenen Funktion:
 * return true => wie ein continue
 * return false => wie ein break
 *
 *
 */
hx.foreach = function (arr, func) {
    if (hx.is_numeric(arr.length)) {
        for (var i = 0; i < arr.length; i++) {
            var retVal = func(arr[i], i);
            if (retVal === false) break;
            if (retVal === true) continue;
        }
    } else {
        for (var i in arr) {
            var retVal = func(arr[i], i);
            if (retVal === false) break;
            if (retVal === true) continue;
        }

    }
};

//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//###################################################################################             ######################################################################################################
//################################################################################### Ab hier DOM ######################################################################################################
//###################################################################################             ######################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################


/**
 * Abk�rzung f�r document.getElementById().
 * Hat einen Cache um Performance f�r �ltere Browser zu verbessern
 *
 * @param {string} id id des Tags
 * @param {boolean} cache caching ein/ausschalten
 *
 * @return {Element}
 */
hx.obj = function (id, cache) {
    return document.getElementById(id);
};

/**
 * Ein DOM-Element vor bestimmter Stelle einf�gen
 *
 * @param objNew  - Das einzuf�gende Element
 * @param objTarget - Das Zielelement neben dem es eingef�gt wird
 */
hx.objInsertBefore = function (objNew, objTarget) {
    objTarget.parentNode.insertBefore(objNew, objTarget);
};

/**
 * Ein DOM-Element nach bestimmter Stelle einf�gen
 *
 * @param objNew  - Das einzuf�gende Element
 * @param objTarget - Das Zielelement neben dem es eingef�gt wird
 */
hx.objInsertAfter = function (objNew, objTarget) {
    objTarget.parentNode.insertBefore(objNew, objTarget.nextSibling);
};

/**
 * Ein DOM-Objekt l�schen
 *
 * @param obj zu l�schendes DOM-Objekt
 */
hx.objRemove = function (obj) {
    obj.parentNode.removeChild(obj);
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der hinzugef�gt wird
 */
hx.classNameAdd = function (obj, classname) {
    var isOldBrowser = false;
    var oldClassname = obj.getAttribute('class');

    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) {
            oldClassname = "";
        } else {
            isOldBrowser = true;
        }
    } else {
        oldClassname = " " + oldClassname + " ";
    }

    if (oldClassname.indexOf(' ' + classname + ' ') >= 0) return;

    if (isOldBrowser) {
        obj.className = hx.trim(hx.trim(oldClassname) + ' ' + classname);
    } else {
        obj.setAttribute('class', hx.trim(hx.trim(oldClassname) + ' ' + classname));
    }
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der entfernt wird
 */
hx.classNameRemove = function (obj, classname) {
    var isOldBrowser = false;
    var oldClassname = obj.getAttribute('class');

    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) {
            oldClassname = "";
        } else {
            oldClassname = " " + oldClassname + " ";
            isOldBrowser = true;
        }
    } else {
        oldClassname = " " + oldClassname + " ";
    }

    if (oldClassname.indexOf(' ' + classname + ' ') >= 0) {
        if (isOldBrowser) {
            obj.className = hx.trim(hx.replace(oldClassname, ' ' + classname + ' ', ' '));
        } else {
            obj.setAttribute('class', hx.trim(hx.replace(oldClassname, ' ' + classname + ' ', ' ')));
        }
    }
};

/**
 *
 * @param obj       - DOM Objekt
 * @param classname - ClassName der gesucht ist
 */
hx.classNameExists = function (obj, classname) {
    var oldClassname = obj.getAttribute('class');

    if (oldClassname === null) {
        oldClassname = obj.className;
        if (oldClassname == null) oldClassname = "";
    } else {
        oldClassname = hx.trim(oldClassname);
    }
    var splitClassNames = hx.explode(" ", oldClassname);
    return hx.in_array(splitClassNames, classname);
};

hx.stringReverse = function (string) {
    return string.split("").reverse().join("");
};

/**
 * Wenn Seite geladen, dann f�hre FN aus.
 *
 *
 * @param {function} fn  auszuf�hrende Funktion
 * @param {string} readystate "complete" (seite komplett geladen) oder "interactive" (dom bereit)
 *
 */
hx.onPageReady = function (fn, readystate) {
    if (!readystate) readystate = "complete";
    var readyStateCheckInterval = setInterval(function () {
        if (document.readyState === readystate) {
            try {
                fn();
            } catch (e) {
                clearInterval(readyStateCheckInterval);
                throw "Error in 'hx.onPageReady': " + e.message;
            }
            clearInterval(readyStateCheckInterval);
        }
    }, 10);
};


/**
 * Hinzuf�gen von auszuf�hrenden Funktionen zu Events.
 * Bereits hinzugef�gte werden nicht gel�scht.
 *
 * Neue Events: Mouseenter,Mouseleave =>
 *                  Events beziehen sich nur auf das definierte Objekt. Kindelemente verursachen kein "Mouseleave"
 *
 *              Focusin,Focusout =>
 *                  Events beziehen sich nur auf das definierte Objekt. Kindelemente verursachen kein "Focusout"
 *
 * @param {Element} obj DOM-Objekt
 * @param {string} types Eventtyp ohne "on" z.B. "click" statt "onclick" => bei mehreren Events diese beistrichtgetrennt angeben
 * @param {string} fn Auszuf�hrende Funktion
 *
 */
hx.eventAddCall = function (obj, types, fn) {
    types = hx.replace(types, " ", "");
    types = hx.explode(",", types);
    hx.foreach(types, function (type) {
        if (obj.addEventListener) {
            if (type == "mouseenter") {
                obj.addEventListener("mouseover", hx._objEnter(fn), false);
            } else if (type == "mouseleave") {
                obj.addEventListener("mouseout", hx._objEnter(fn), false);
            } else if (type == "focusin") {
                obj.addEventListener("focus", fn, false);
                hx._setFocus(obj);
            } else if (type == "focusout") {
                obj.addEventListener("blur", fn, false);
            } else {
                obj.addEventListener(type, fn, false);
            }
        } else if (obj.attachEvent) {
            obj["e" + type + fn] = fn;
            obj[type + fn] = function (e) {
                if (!e) e = window.event;
                if (!e.target) e.target = e.srcElement;
                if (!e.which) e.which = e.keyCode;
                obj["e" + type + fn](e);
            };
            if (type == "focusin") {
                obj.attachEvent("onfocus", fn);
                hx._setFocus(obj);
            } else if (type == "focusout") {
                obj.attachEvent("onblur", fn);
            } else {
                obj.attachEvent("on" + type, obj[type + fn]);
            }
        }
        if (!obj.hxEventFnList) obj.hxEventFnList = new Object();
        obj.hxEventFnList[hx.count(obj.hxEventFnList)] = fn;
    });
};

/**
 * Alle Callbackfunktionen entfernen zu einem Event
 *
 * @param obj
 * @param type
 */
hx.eventRemoveAllCalls = function (obj, type) {
    if (hx.is_object(obj.hxEventFnList)) {
        hx.foreach(obj.hxEventFnList, function (evFn) {
            hx.eventRemoveCall(obj, type, evFn);
        });
    }
};

/**
 * Auf eine Gruppe von Elementen ein oder mehrere Events registrieren. Auch neu erstellte Elemente reagieren auf dieses Event.
 *
 *
 * @param cssSelector
 * @param types
 * @param fn
 */
hx.eventAddCallLive = function (cssSelector, types, fn) {
    //hx.eventAddCall(document.body,types,function(e){
    //    if(!e.target) e.target = e.srcElement;
    //    var el = e.target,found;
    //
    //    while (el && !(found = hx.in_array( hx.objByCssSelector(cssSelector),el ))) {
    //        el = el.parentElement;
    //    }
    //    if (found) {
    //        fn.call(el, e);
    //    }
    //
    //});
    hx.eventAddCallLive.id = hx.cint(hx.eventAddCallLive.id) + 1;

    var func = "" +
        "if(!e.target) e.target = e.srcElement;" +
        "var el = e.target,found;" +
        "while (el && !(found = hx.in_array( hx.objByCssSelector(\"" + cssSelector + "\"),el ))) {" +
        "el = el.parentElement;" +
        "}" +
        "if (found) {" +
        "(" + (fn) + ").call(el, e);" +
        "}" +
        "/*" + hx.eventAddCallLive.id + "*/" +
        "";

    hx.eventAddCall(document.body, types, new Function("e", func));
};


hx.eventFire = function (obj, type) {
    if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent(type, false, true);
        obj.dispatchEvent(evt);
    } else {
        obj.fireEvent("on" + type);
    }
};


hx.eventDblClickBlock = function (objArr) {
    hx.foreach(objArr, function (link) {
        if (link.getAttribute('eventDblClickBlock') === "0" || link.getAttribute('eventDblClickBlock') === "1") return true;
        link.setAttribute('eventDblClickBlock', "0");
        hx.eventAddCall(link, 'click', function (e) {
            if (!e) e = window.event;
            if (!e.target) e.target = e.srcElement;
            if (this.getAttribute('eventDblClickBlock') == "0") {
                this.setAttribute('eventDblClickBlock', "1");
                var thisElem = this;
                setTimeout(function () {
                    thisElem.setAttribute('eventDblClickBlock', "0");
                }, 1000);
            } else {
                if (e.preventDefault) e.preventDefault();
                e.returnValue = false;
                return false;
            }
        });
    });
};


/*function fireEvent(element,event){
 if (document.createEventObject){
 // dispatch for IE
 var evt = document.createEventObject();
 return element.fireEvent('on'+event,evt)
 }
 else{
 // dispatch for firefox + others
 var evt = document.createEvent("HTMLEvents");
 evt.initEvent(event, true, true ); // event type,bubbling,cancelable
 return !element.dispatchEvent(evt);
 }
 }*/
hx._setFocus = function (obj) {
    hx.foreach(obj.getElementsByTagName('*'), function (elem, key) {
        hx.eventAddCall(elem, "focus", function (event) {
            obj.focus();
        });
    });
};

hx._objEnter = function (fn) {

    return function (evt) {
        var relTarget = evt.relatedTarget;
        if (this === relTarget || hx.objIsAChildOf(this, relTarget)) {
            return;
        }
        fn.call(this, evt);
    }
};

/**
 * Pr�fen ob Dom-Objekte in anderen Elementen enhalten sind
 *
 * @param parent - Objekt in dem gesucht wird
 * @param child  - Objekt welches gesucht wird
 * @param isDirectDescendent - Soll es ein direktes Unterelement sein
 * @returns {boolean}
 */
hx.objIsAChildOf = function (parent, child, isDirectDescendent) {
    if (parent === child) {
        return false;
    }
    while (child && child !== parent) {
        child = child.parentNode;
        if (isDirectDescendent === true) break;
    }
    return child === parent;
};


/**
 * Entfernen einer Funktion zu einem Event.
 * Bereits hinzugef�gte werden nicht gel�scht
 *
 * @param {Element} obj DOM-Objekt
 * @param {string} type Eventtyp ohne "on" z.B. "click" statt "onclick"
 * @param {string} fn Funktionsname der auszuf�hrenden Funktion
 *
 */
hx.eventRemoveCall = function (obj, type, fn) {
    if (obj.removeEventListener) {
        obj.removeEventListener(type, fn, false);

    } else if (obj.detachEvent) {
        obj.detachEvent("on" + type, obj[type + fn]);
        obj[type + fn] = null;
        obj["e" + type + fn] = null;
    }
};


/**
 * Event abbrechen und jegliche weiterleitung zu anderen Objekten verhindern
 *
 * @param {Event} e Event
 *
 */
hx.eventCancelBubble = function (e) {
    if (!e) e = window.event;
    try {
        e.stopPropagation();
    } catch (ex) {
    }
    try {
        e.cancelBubble = true;
    } catch (ex) {
    }
};


/**
 * Aktuellen Wert eines SELECT-Formfields auslesen
 *
 * @param {Element} obj DOM des SELECT
 *
 * @return {string}
 */
hx.inputSelectGetValue = function (obj) {
    return hx.getSelectFieldData(obj, "value");
};

/**
 * Aktuellen Text eines SELECT-Formfields auslesen
 *
 * @param {Element} obj                - DOM des SELECT
 *
 * @return {string}
 */
hx.inputSelectGetText = function (obj) {
    return hx.getSelectFieldData(obj, "text");
};

/**
 * Aktuellen Wert eines SELECT-Formfields setzen
 *
 * @param {Element} obj               - DOM des SELECT
 * @param {string} value          - Value im Selectfield
 *
 * @return {string}
 */
hx.inputSelectSetValue = function (obj, value) {
    return hx.setSelectFieldData(obj, value, "value");
};

/**
 * Aktuellen Wert eines SELECT-Formfields an die Stelle des �bergebenen Texts setzen
 *
 * @param {Element} obj               - DOM des SELECT
 * @param {string} value          - Text im Selectfield
 *
 * @return {string}
 */
hx.inputSelectSetText = function (obj, value) {
    return hx.setSelectFieldData(obj, value, "text");
};

/**
 * Alle Werte eines SELECT-Formfields als Array holen
 *
 * @param {Element} obj                - DOM des SELECT
 *
 * @return Array ergebnis
 */
hx.inputSelectGetValueArray = function (obj) {
    return hx.getSelectFieldDataAsArray(obj, "value");
};

/**
 * Alle Texte eines SELECT-Formfields als Array holen
 *
 * @param {Element} obj                - DOM des SELECT
 *
 * @return Array ergebnis
 */
hx.inputSelectGetTextArray = function (obj) {
    return hx.getSelectFieldDataAsArray(obj, "text");
};

/**
 * Aktuelle Position des Cursors in Textarea/Input auslesen
 *
 * @param {Element} obj                - DOM
 *
 * @return {number}
 */
hx.inputTextGetCaretPos = function (obj) {
    var CaretPos = 0;
    // IE Support
    if (document.selection) {
        obj.focus();
        var Sel = document.selection.createRange();
        Sel.moveStart('character', -obj.value.length);
        CaretPos = Sel.text.length;
    } else if (obj.selectionStart || obj.selectionStart == '0') {
        CaretPos = obj.selectionStart;
    }
    return (CaretPos);
};

/**
 * Aktuelle Position des Cursors in Textarea/Input setzen
 *
 * @param {Element} obj                - DOM
 * @param {number} pos            - Position
 */
hx.inputTextSetCaretPos = function (obj, pos) {
    if (obj.setSelectionRange) {
        obj.focus();
        obj.setSelectionRange(pos, pos);
    } else if (obj.createTextRange) {
        var range = obj.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
};


/**
 * Inhalt eines JS-Arrays(Objekts) zu einem Formular hinzuf�gen
 *
 * hx.array2input(new Array(1,2,3),"myArray",document.f)
 *
 * @param arr       - Das Array/Objekt
 * @param name      - Name im Formular
 * @param form      - Das Formular welchem Felder hinzugef�gt werden
 * @returns {string}
 */
hx.array2input = function (arr, name, form) {
    var t = "";
    for (var k in arr) {
        var v = arr[k];
        if (hx.is_array(v) || hx.is_object(v)) {
            t += "" + hx.array2input(v, name + "[" + k + "]", form) + "";
        } else {
            if (v === true) {
                v = 1;
            } else if (v === false) {
                v = 0;
            }
            var input = document.createElement("input");
            input.name = name + "[" + k + "]";
            input.type = "hidden";
            input.value = v;
            form.appendChild(input);
            t += input.outerHTML;
        }
    }
    return t;
};

/**
 * Aktuellen Wert eines SELECT-Formfields auslesen
 *
 * @param {Element} obj DOM des SELECT
 * @param {string} what "value"=>liest den Wert aus dem Element aus und "text"=>liest den Text der an der Oberfl�che ausgegeben wird aus
 *
 * @return {string}
 */
hx.getSelectFieldData = function (obj, what) {
    if (!what) what = "value";
    var arr = obj.options;
    return arr[obj.selectedIndex][what];
};

/**
 * Werte eines SELECT-Formfields lesen als Array
 *
 * @param {Element} obj DOM des SELECT
 * @param {string} what "value"=>liest den Wert aus dem Element aus und "text"=>liest den Text der an der Oberfl�che ausgegeben wird aus
 *
 * @return Array
 */
hx.getSelectFieldDataAsArray = function (obj, what) {
    if (!what) what = "value";
    var arr = obj.options;
    var resArr = new Array();
    for (var i = 0; i < arr.length; i++) {
        resArr.push(arr[i][what]);
    }
    return resArr;
};

/**
 * Aktuellen Wert eines SELECT-Formfields setzen
 *
 * @param {object} obj DOM des SELECT
 * @param value
 * @param what         "value"=>sucht nach dem Wert aus dem Element und "text" sucht nach dem Wert der an der Oberfl�che ausgegeben wird aus
 *
 * @returns {boolean}
 */
hx.setSelectFieldData = function (obj, value, what) {
    if (!what) what = "value";
    var arr = obj.options;
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][what] == value) {
            obj.selectedIndex = i;
            return true;
        }
    }
    return false;
};

/**
 * Absolute Position eines DOM-Objekts von links im Fenster auslesen
 *
 * @param {object} o
 *
 * @return {number}
 */
hx.getAbsoluteObjectPosLeft = function (o) {
    var l = o.offsetLeft;
    if (o.offsetParent) l = l + hx.getAbsoluteObjectPosLeft(o.offsetParent);
    return l;
};

/**
 * Absolute Position eines DOM-Objekts von oben im Fenster auslesen
 *
 * @param {object} o
 *
 * @return {number}
 */
hx.getAbsoluteObjectPosTop = function (o) {
    var t = o.offsetTop;
    if (o.offsetParent) t = t + hx.getAbsoluteObjectPosTop(o.offsetParent);
    return t;
};

/**
 * Absolute Position eines DOM-Objekts von oben im Fenster auslesen
 *
 * @param {object} o
 *
 * @return {object}
 */
hx.getAbsoluteObjectPosArray = function (o) {
    var t = 0;
    var t2 = 0;

    while (o) {
        t = t + o.offsetTop;
        t2 = t2 + o.offsetLeft;
        o = o.offsetParent;
    }

    var pos = new Object();
    pos.top = t;
    pos.left = t2;
    return pos;
};


/**
 * Hintergrundshade wird eingef�gt und �bergebenes DOM-Obj im Vordergrund zentriert angezeigt.
 *
 * @param {object} obj          - Ein DOM-Obj mit definierter H�he und Breite (zB DIV oder IMG)
 * @param {string} bgcolor  - Farbe des Overlays (optional, default black)
 * @param {number} opacity  - Durchsichtigkeit von 0-1 (optional, default 0.75)
 * @param {number} zindex   - zindex  (optional, default 10000)
 * @param onClickShadeClose
 * @param closefunction
 */
hx.guiOverlayOpen = function (obj, bgcolor, opacity, zindex, onClickShadeClose, closefunction) {
    var el_div;
    if (document.getElementById("hx_lightbox_bg")) {
        el_div = document.getElementById("hx_lightbox_bg");
    } else {
        el_div = document.createElement("div");
        document.body.appendChild(el_div);
    }
    if (bgcolor == null) {
        bgcolor = "black";
    }
    if (opacity == null) {
        opacity = 0.5;
    }
    if (zindex == null) {
        zindex = 10000;
    }

    var el_show = obj;

    // IE 6
    if (typeof document.body.style.maxHeight == "undefined") {
        el_show.style.position = "absolute";
        el_div.style.position = "absolute";
        el_div.style.height = el_div.style.width = "5000px";
    } else {
        el_show.style.position = "fixed";
        el_div.style.position = "fixed";
        el_div.style.height = el_div.style.width = "100%";
    }

    el_div.id = "hx_lightbox_bg";
    //    el_div.style.position = "fixed";
    el_div.style.backgroundColor = bgcolor;
    el_div.style.top = el_div.style.left = 0;

    el_div.style.opacity = opacity;
    el_div.style.filter = "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + (opacity * 100) + ")";
    el_div.style.zIndex = zindex;
    el_div.style.display = "";

    el_show.style.display = "";
    el_show.style.left = el_show.style.top = "50%";
    el_show.style.marginTop = -(hx.cint(el_show.style.height) / 2) + "px";
    el_show.style.marginLeft = -(hx.cint(el_show.style.width) / 2) + "px";
    el_show.style.marginRight = el_show.style.marginBottom = 0;
    //    el_show.style.position = "fixed";
    el_show.style.zIndex = zindex + 1;

    if (closefunction) {
        el_show.overlayCloseFunction = closefunction;

    }
    if (!el_div.onclick && onClickShadeClose == true) {
        el_div.onclick = function () {
            hx.guiOverlayClose(el_show);
            //document.getElementById("hx_lightbox_bg").style.display = "none";
            //el_show.style.display = "none";

        };
    }
};

/**
 * Hintergrundshade mit daraufliegendem DOM-Obj ausblenden
 *
 *
 * @param {Element} obj Das auszublendende DOM-Obj
 *
 * @return {number}
 */
hx.guiOverlayClose = function (obj) {
    var el_show = obj;

    if (el_show.overlayCloseFunction) {
        el_show.overlayCloseFunction();
    }

    document.getElementById("hx_lightbox_bg").style.display = "none";
    el_show.style.display = "none";
};


/**
 * Text in Blockelement mit fixer Breite beschneiden mit ...
 *
 * @param {string} str
 * @param {number} px auf Breite beschneiden
 * @param {string} align Wo beschneiden (right,middle)
 * @param {string} className
 *
 * @returns {string}
 */
hx.guiSetEllipsis = function (str, px, align, className) {
    var span = document.createElement("span");
    span.className = className;
    span.style.visibility = 'hidden';
    span.style.padding = '0px';
    span.style.whiteSpace = 'nowrap';
    span.innerHTML = str + '';
    document.body.appendChild(span);
    var c = 0;
    var s = '';
    var n;
    var w = span.offsetWidth;
    if (w > px) {
        if (align == 'center') {
            c = Math.floor(str.length / 2);
            //console.log("C "+c);

            for (n = c - 2; n > 0; n--) {
                s = str.substr(0, n) + '&hellip;' + str.substr(str.length - n, 10000);
                span.innerHTML = s;
                //console.log(span.offsetWidth+" <= "+px);

                if (span.offsetWidth <= px) {
                    //console.log("BREAK");
                    break;
                }
            }
            str = s;
        }
        if (align == 'left') {
            for (n = str.length - 1; n > 0; n = n - 2) {
                s = str.substr(0, n) + '&hellip;';
                span.innerHTML = s;
                if (span.offsetWidth <= px) break;
            }
            str = s;
        }
        if (align == 'right') {
            for (n = str.length - 1; n > 0; n = n - 2) {
                s = '&hellip;' + str.substr(str.length - n, 10000);
                span.innerHTML = s;
                if (span.offsetWidth <= px) break;
            }
            str = s;
        }
    }

    document.body.removeChild(span);
    return hx.trim(str);
};

/**
 * Loadinganimation einblenden
 * Parent-Element sollte position absolute/relative haben!
 *
 * @param name      eindeutiger Name
 * @param parent    Element in welches es eingebettet wird
 * @param w         Breite
 * @param h         Hoehe
 * @param r         Rotwert
 * @param g         Gruenwert
 * @param b         Blauwert
 */
hx.guiLoaderIconDisplay = function (name, parent, w, h, r, g, b) {
    if (!r) r = 255;
    if (!g) g = 230;
    if (!b) b = 129;
    if (!hx.guiLoaderIconDisplay.loaders) hx.guiLoaderIconDisplay.loaders = new Object();
    if (!hx.guiLoaderIconDisplay.loadersColors) hx.guiLoaderIconDisplay.loadersColors = new Object();
    if (!hx.guiLoaderIconDisplay.loadersRunning) hx.guiLoaderIconDisplay.loadersRunning = new Object();
    if (!hx.guiLoaderIconDisplay.toValid) {
        hx.guiLoaderIconDisplay.toValid = function (val) {
            if (val < 0) {
                return 0;
            } else if (val > 255) {
                val = 255;
            }
            return val;
        };
    }

    if (hx.guiLoaderIconDisplay.loaders[name]) return;
    hx.guiLoaderIconDisplay.loaders[name] = new Array();
    hx.guiLoaderIconDisplay.loadersColors[name] = new Array();

    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r) + ',' + hx.guiLoaderIconDisplay.toValid(g) + ',' + hx.guiLoaderIconDisplay.toValid(b) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 10) + ',' + hx.guiLoaderIconDisplay.toValid(g - 10) + ',' + hx.guiLoaderIconDisplay.toValid(b - 10) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 20) + ',' + hx.guiLoaderIconDisplay.toValid(g - 20) + ',' + hx.guiLoaderIconDisplay.toValid(b - 20) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 30) + ',' + hx.guiLoaderIconDisplay.toValid(g - 30) + ',' + hx.guiLoaderIconDisplay.toValid(b - 30) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 40) + ',' + hx.guiLoaderIconDisplay.toValid(g - 40) + ',' + hx.guiLoaderIconDisplay.toValid(b - 40) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 50) + ',' + hx.guiLoaderIconDisplay.toValid(g - 50) + ',' + hx.guiLoaderIconDisplay.toValid(b - 50) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 40) + ',' + hx.guiLoaderIconDisplay.toValid(g - 40) + ',' + hx.guiLoaderIconDisplay.toValid(b - 40) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 30) + ',' + hx.guiLoaderIconDisplay.toValid(g - 30) + ',' + hx.guiLoaderIconDisplay.toValid(b - 30) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 20) + ',' + hx.guiLoaderIconDisplay.toValid(g - 20) + ',' + hx.guiLoaderIconDisplay.toValid(b - 20) + ')');
    hx.guiLoaderIconDisplay.loadersColors[name].push('rgb(' + hx.guiLoaderIconDisplay.toValid(r - 10) + ',' + hx.guiLoaderIconDisplay.toValid(g - 10) + ',' + hx.guiLoaderIconDisplay.toValid(b - 10) + ')');


    //    parent.style.position = 'relative';
    //    parent.style.width = w+'px';
    //    parent.style.height = h+'px';

    var width_elem = hx.cint(w / 10 - 4);
    var height_elem = h;

    var div = document.createElement('DIV');
    div.style.width = width_elem + 'px';
    div.style.height = height_elem + 'px';
    div.style.marginLeft = '2px';
    div.style.left = '-2px';
    div.style.border = '1px solid rgb(' + hx.guiLoaderIconDisplay.toValid(r - 55) + ',' + hx.guiLoaderIconDisplay.toValid(g - 55) + ',' + hx.guiLoaderIconDisplay.toValid(b - 55) + ')';
    if (w < 60) div.style.border = '1px solid transparent';
    div.style.position = 'absolute';
    div.style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][0];
    hx.guiLoaderIconDisplay.loaders[name].push(div);
    parent.appendChild(div);

    for (var i = 1; i < 10; i++) {
        div = div.cloneNode(true);
        div.style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][i];
        hx.guiLoaderIconDisplay.loaders[name].push(div);
        parent.appendChild(div);
        div.style.left = div.previousSibling.offsetLeft + div.previousSibling.offsetWidth + 'px';
    }


    hx.guiLoaderIconDisplay.loadersRunning[name] = setInterval(function () {
        var length = 0;
        for (var i in hx.guiLoaderIconDisplay.loadersColors[name]) {
            length++;
        }
        var t = hx.guiLoaderIconDisplay.loadersColors[name][length - 1];
        hx.guiLoaderIconDisplay.loadersColors[name].splice(length - 1, 1);
        hx.guiLoaderIconDisplay.loadersColors[name].unshift(t);

        for (var i in hx.guiLoaderIconDisplay.loaders[name]) {
            hx.guiLoaderIconDisplay.loaders[name][i].style.backgroundColor = hx.guiLoaderIconDisplay.loadersColors[name][i];
        }

        hx.forceBrowserRepaint();
    }, 100);


};

/**
 * Loadinganimation beenden
 *
 * @param name      eindeutiger Name
 */
hx.guiLoaderIconKill = function (name) {
    clearInterval(hx.guiLoaderIconDisplay.loadersRunning[name]);
    hx.guiLoaderIconDisplay.loadersRunning[name] = null;
    for (var i in hx.guiLoaderIconDisplay.loaders[name]) {
        if (hx.guiLoaderIconDisplay.loaders[name][i] && hx.guiLoaderIconDisplay.loaders[name][i].parentNode) hx.guiLoaderIconDisplay.loaders[name][i].parentNode.removeChild(hx.guiLoaderIconDisplay.loaders[name][i]);
    }
    hx.guiLoaderIconDisplay.loadersColors[name] = null;
    hx.guiLoaderIconDisplay.loaders[name] = null;
};


/**
 * SVG Container erstellen
 *
 * @param {object} parent_element DOM-Obj f�r Einbettung
 * @param {number} w              Breite in px
 * @param {number} h              Hoehe in px
 *
 * @return {number|HTMLElement} null, falls nicht unterstuetzt
 */
hx.guiCreateSVGContainer = function (parent_element, w, h) {
    if (hx.isSVGSupported()) {
        var ns = 'http://www.w3.org/2000/svg';
        var svg = document.createElementNS(ns, 'svg');
        svg.setAttribute('width', w);
        svg.setAttribute('height', h);
        parent_element.appendChild(svg);
        return svg;
    } else {
        return null;
    }
};


/**
 * SVG Browserunterstuetzung pruefen
 * @return false falls nicht unterstuetzt
 */
hx.isSVGSupported = function () {
    return document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#BasicStructure', '1.1');
};


/**
 * Alle DOM-ELemente finden die ein bestimmtes Attribut haben
 *
 *
 * @param {string} attr         Das zu suchende Attribut
 * @param {string} tagname      Attribut nur in bestimmtem Tag suchen (schneller... f�r IE)
 * @param {string} cache        cachen, default=false
 * @param {string} objSearchIn  Suche eingrenzen in einem Objekt (cache deaktiviert)
 * @param {string} attrValue    Suche eingrenzen auf Wert des Attributs (cache deaktiviert)
 *
 * @return Array
 */
hx.objByAttributeName = function (attr, tagname, cache, objSearchIn, attrValue) {
    if (!tagname) tagname = "*";

    if (hx.isFastBrowser()) {
        if (!objSearchIn) objSearchIn = document;
        if (attrValue) {
            var objs = objSearchIn.querySelectorAll(tagname + "[" + attr + "=\"" + attrValue + "\"]");
        } else {
            var objs = objSearchIn.querySelectorAll(tagname + "[" + attr + "]");
        }
        var erg = new Array();
        for (var i = 0; i < objs.length; i++) {
            erg.push(objs[i]);
        }
        return erg;
    }

    if (objSearchIn || attrValue) cache = false;
    if (cache) {
        if (!hx.objByAttributeName.cache) hx.objByAttributeName.cache = new Object();
        if (hx.objByAttributeName.cache[attr]) return hx.objByAttributeName.cache[attr];
    }
    if (objSearchIn) {
        var all = objSearchIn.getElementsByTagName(tagname);
    } else {
        var all = document.getElementsByTagName(tagname);
    }
    var erg = new Array();
    for (var i = 0; i < all.length; i++) {
        if (hx.is_string(all[i].getAttribute(attr))) {
            if (attrValue) {
                if (attrValue == all[i].getAttribute(attr)) erg.push(all[i]);
            } else {
                erg.push(all[i]);
            }
        }
    }
    if (cache) hx.objByAttributeName.cache[attr] = erg;
    return erg;
};

/**
 * Zwingt Browser durch tempor�res Einf�gen eines DOM-Elements die Seite neu zu rendern
 */
hx.forceBrowserRepaint = function () {
    var t = document.createTextNode(' ');
    document.body.appendChild(t);
    document.body.removeChild(t);
};


/**
 * Funktioniert wie document.querySelectorAll
 * Holt alle DOM-Objekte auf die der �bergebene CSS-Selector zutrifft
 *
 * @param {string} query  CSS Selector
 * @param {object} obj    DOM-Objekt in dem gesucht werden soll
 * @returns Array
 */
hx.objByCssSelector = function (query, obj) {
    if (obj) return $(obj).find(query).toArray();
    return $(query).toArray();
};


hx._fadeGetUniqueId = function (obj) {
    if (!hx._fadeGetUniqueId.cache) hx._fadeGetUniqueId.cache = new Array();

    for (var i in hx._fadeGetUniqueId.cache) {
        if (hx._fadeGetUniqueId.cache[i] == obj) return hx.cint(i);
    }

    hx._fadeGetUniqueId.cache.push(obj);

    return hx._fadeGetUniqueId.cache.length - 1;
};

/**
 * Blendet DOM-Obj ein.
 *
 *
 * @param {Element} obj           Das einzublendende DOM-Obj
 * @param {number} cycles         In wie viel Schritten soll das Einblenden passieren? 1 Schritt ca. 1 Millisekunde
 * @param {function} successFunc  Funktion die am Ende der Animation ausgefuehrt werden soll
 *
 */
hx.fadeIn = function (obj, cycles, successFunc) {
    if (!hx.fadeIn.fade) hx.fadeIn.fade = new Object;
    if (!hx.fadeOut.fade) hx.fadeOut.fade = new Object;
    cycles = hx.cint(cycles) + 1;

    //clearInterval(hx.fadeIn.fade[obj]);
    clearInterval(hx.fadeOut.fade[obj]);
    if (hx.isFastBrowser()) {
        obj.style.opacity = 0;
    } else {
        obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    }

    var step = 1 / cycles;
    var cyclesDone = 1;

    var objCacheKey = hx._fadeGetUniqueId(obj);


    hx.fadeIn.fade[objCacheKey] = setInterval(function () {
        if (cyclesDone == cycles) {
            clearInterval(hx.fadeIn.fade[objCacheKey]);
            if (hx.isFastBrowser()) {
                obj.style.opacity = 0;
            } else {
                obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
            }
            if (successFunc) successFunc();
        }
        if (hx.isFastBrowser()) {
            obj.style.opacity = step * cyclesDone;
        } else {
            obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + (step * cyclesDone * 100) + ')';
        }
        cyclesDone++;
    }, 1);
};

/**
 * Blendet DOM-Obj aus.
 *
 *
 * @param {Element} obj            Das auszublendende DOM-Obj
 * @param {number} cycles          In wie viel Schritten soll das Ausblenden passieren? 1 Schritt ca. 1 Millisekunde
 * @param {function} successFunc   Funktion die am Ende der Animation ausgefuehrt werden soll
 *
 */
hx.fadeOut = function (obj, cycles, successFunc) {
    if (!hx.fadeIn.fade) hx.fadeIn.fade = new Object;
    if (!hx.fadeOut.fade) hx.fadeOut.fade = new Object;
    cycles = hx.cint(cycles) + 1;

    clearInterval(hx.fadeIn.fade[obj]);
    //clearInterval(hx.fadeOut.fade[obj]);
    if (hx.isFastBrowser()) {
        obj.style.opacity = 1;
    } else {
        obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    var step = 1 / cycles;
    var cyclesDone = 1;

    var objCacheKey = hx._fadeGetUniqueId(obj);

    hx.fadeOut.fade[objCacheKey] = setInterval(function () {
        if (cyclesDone == cycles) {
            clearInterval(hx.fadeOut.fade[objCacheKey]);
            //obj.style.display = 'none';
            if (hx.isFastBrowser()) {
                obj.style.opacity = 0;
            } else {
                obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
            }
            if (successFunc) successFunc();
        }
        if (hx.isFastBrowser()) {
            obj.style.opacity = 1 - step * cyclesDone;
        } else {
            obj.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + ((1 - step * cyclesDone) * 100) + ')';
        }
        cyclesDone++;
    }, 1);
};


hx.isVisible = function (o) {
    while (true) {
        if (o.style.display == 'none' || o.style.visibility == 'hidden') return false;
        if (o.filters) {
            if (o.filters.item("DXImageTransform.Microsoft.Alpha").opacity == 0) return false;
        }
        if (o.style.opacity) {
            if (o.style.opacity == 0) return false;
        }
        if (o == document.body) break;
        o = o.parentNode;
    }
    return true;
};


hx.debugs = function () {
    try {
        console.log.apply(console, arguments);
    } catch (e) {

    }
};

hx.isInt = function (n) {
    return n % 1 === 0;
};


hx.guiSetInputFieldStyleAllowNull = function (obj, allowNull) {
    if (!obj) return;
    var tagname = obj.tagName.toLowerCase();
    if (tagname == 'select') {
        if (allowNull) {
            hx.classNameRemove(obj, 'formselectnotnull');
            hx.classNameAdd(obj, 'formselect');
            obj.onblur = function () {
                this.className = 'formselect';
            };
            obj.onfocus = function () {
                this.className = 'formselectfocus';
            };
        } else {
            hx.classNameRemove(obj, 'formselect');
            hx.classNameAdd(obj, 'formselectnotnull');
            obj.onblur = function () {
                this.className = 'formselectnotnull';
            };
            obj.onfocus = function () {
                this.className = 'formselectnotnullfocus';
            };
        }
    } else {
        if (allowNull) {
            hx.classNameRemove(obj, 'formtextnotnull');
            hx.classNameAdd(obj, 'formtext');
            obj.onblur = function () {
                this.className = 'formtext';
            };
            obj.onfocus = function () {
                this.className = 'formtextfocus';
            };
        } else {
            hx.classNameRemove(obj, 'formtext');
            hx.classNameAdd(obj, 'formtextnotnull');
            obj.onblur = function () {
                this.className = 'formtextnotnull';
            };
            obj.onfocus = function () {
                this.className = 'formtextnotnullfocus';
            };
        }
    }
};

//hx.dom = function(strobj){ return new hx._dom(strobj);};
//
//hx._dom = function(strobj){
//    this.objs = null;
//    if(hx.is_string(strobj)){
//        this.objs = hx.objByCssSelector(strobj);
//    } else if(hx.is_array(strobj)){
//        this.objs = strobj;
//    } else {
//        this.objs = new Array(strobj);
//    }
//
//    this.onmouseover = function(func){
//        hx.foreach(this.objs,function(elem){
//            elem.onmouseover = func;
//        });
//    };
//
//    this.eventAddCall = function(event,func){
//        hx.foreach(this.objs,function(elem){
//            hx.eventAddCall(elem,event,func);
//        });
//    };
//
//    this.style = function(style,value){
//        hx.foreach(this.objs,function(elem){
//            elem.style[style] = value;
//        });
//    };
//
//};


//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//###################################################################################               ####################################################################################################
//################################################################################### Ab hier Debug ####################################################################################################
//###################################################################################               ####################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################


hx.debug = function () {
};


hx.debug.toggleGrid = function () {
    var debuggrid = document.getElementsByTagName('td');
    for (var i in debuggrid) {
        if (debuggrid[i].style) {
            if (debuggrid[i].style.outline.indexOf('red') < 0) {
                debuggrid[i].style.outline = '1px solid red';
            } else {
                debuggrid[i].style.outline = '0px';
            }
        }
    }
};

hx.debug.debug_eval = function () {
    var t = eval;
    eval = function (txt) {
        try {
            console.log(txt);
            return t(txt);
        } catch (e) {
            console.log(e, txt);
        }
    };
};

hx.debug.get_unused_styles = function () {
    var stylesheets = document.styleSheets;
    var txt = "";
    if (!hx.obj('debug_unused_styles')) {
        var t = document.createElement('div');
        t.setAttribute("id", "debug_unused_styles");
        t.style.height = t.style.width = "500px";
        t.style.overflow = "scroll";
        t.style.display = "none";
        t.style.backgroundColor = "white";
        t.style.fontSize = "10px";
        document.body.appendChild(t);
    }
    for (var h = 0; h < stylesheets.length; h++) {
        var cssr = document.styleSheets[h].cssRules;
        var stylename = hx.basename(document.styleSheets[h].href);
        if (stylename.length == 0) stylename = "inline";
        txt += "<div style='font-weight:bold;margin:5px;background-color:black;color:white;'>Stylesheet Nr. " + (h + 1) + " von " + stylesheets.length + " (" + stylename + ")</div>";
        for (var i = 0; i < cssr.length; i++) {
            if (cssr[i].selectorText) {
                var t = cssr[i].selectorText.split(',');
                for (var j = 0; j < t.length; j++) {
                    try {
                        if (!document.querySelector(hx.trim(t[j]))) {
                            txt += "<div style='margin-left:10px;'>" + hx.trim(t[j]) + '<br></div>';
                        }
                    } catch (ex) {
                    }
                }
            }
        }
    }

    hx.obj('debug_unused_styles').innerHTML = txt;
    hx.guiOverlayOpen(hx.obj('debug_unused_styles'), "#000", 0.5, 10000, true);
};


hx.debug.checkDoubleIds = function () {
    var ids_obj = hx.objByCssSelector('[id]');
    var ids = new Array();
    for (var i in ids_obj) {
        var id_obj = ids_obj[i];
        if (hx.in_array(ids, id_obj.id)) {
            alert('ID: \'' + id_obj.id + '\' wurde mehrmals vergeben!');
            return;
        }
        ids.push(id_obj.id);
    }
    alert("Alles OK");
};


/**
 * Color-Objekt
 * Erlaubt Eingabe von verschiedenen Farbformaten
 * #cccccc
 * rgb(r,g,b)
 * rgba(r,g,b,a)
 * r,g,b
 * index fuer vordefinierte Farbwerte
 * index.kommastelle fuer vordefinierte Werte mit aufhellung
 *
 * @param {string} any_color die Farbe
 */
hx.Color = function (any_color) {
    this.r = 0;
    this.g = 0;
    this.b = 0;
    this.a = 0;
    var a, orig_color, heller = 0;

    if (any_color == undefined) any_color = "white";
    any_color = (any_color + '').toLowerCase();

    // Named colors:
    if (any_color == "black") any_color = "#000000";
    if (any_color == "white") any_color = "#ffffff";
    if (any_color == "red") any_color = "#ff0000";
    if (any_color == "green") any_color = "#00ff00";
    if (any_color == "blue") any_color = "#0000ff";
    if (any_color == "yellow") any_color = "#ffff00";
    if (any_color == "transparent") any_color = "rgba(255,255,255,0)";

    // map Colorindex
    if (any_color.indexOf('rgb') < 0 && any_color.indexOf('#') < 0 && any_color.indexOf(',') < 0) {
        orig_color = any_color;
        any_color = parseInt(any_color, 10) % 14;
        heller = parseFloat(orig_color % 14) - any_color;
        heller = heller * 10;
        switch (any_color) {
            case 0:
                any_color = "#e9d666";
                break;
            case 1:
                any_color = "#c6cb6f";
                break;
            case 2:
                any_color = "#c88a10";
                break;
            case 3:
                any_color = "#aecce7";
                break;
            case 4:
                any_color = "#7299c6";
                break;
            case 5:
                any_color = "#cbb676";
                break;
            case 6:
                any_color = "#d4711a";
                break;
            case 7:
                any_color = "#ffc40b";
                break;
            case 8:
                any_color = "#8b9d3c";
                break;
            case 9:
                any_color = "#bf311a";
                break;
            case 10:
                any_color = "#16619e";
                break;
            case 11:
                any_color = "#003b77";
                break;
            case 12:
                any_color = "#b39601";
                break;
            case 13:
                any_color = "#8d0624";
                break;
            default:
                any_color = "#e9d666";
                break;
        }
    }

    if (any_color.indexOf('rgba') >= 0) {          // RGBA(r,g,b,a)
        any_color = any_color.replace(/rgba/, '');
        any_color = any_color.replace(/\(/, '');
        any_color = any_color.replace(/\)/, '');
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = parseFloat(a[3]);
    } else if (any_color.indexOf('rgb') >= 0) {     // RGB(r,g,b)
        any_color = any_color.replace(/rgb/, '');
        any_color = any_color.replace(/\(/, '');
        any_color = any_color.replace(/\)/, '');
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = 1;
    } else if (any_color.indexOf('#') >= 0) {       // #RRGGBB
        // HEX
        var res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(any_color);
        this.r = parseInt(res[1], 16);
        this.g = parseInt(res[2], 16);
        this.b = parseInt(res[3], 16);
        this.a = 1;
    } else {                                      // r,g,b
        a = any_color.split(',');
        this.r = parseInt(a[0], 10);
        this.g = parseInt(a[1], 10);
        this.b = parseInt(a[2], 10);
        this.a = 1;
    }

    if (heller > 0) {
        this.r = this.r + 15 * heller;
        this.g = this.g + 15 * heller;
        this.b = this.b + 15 * heller;
    }

    if (this.r < 0) this.r = 0;
    if (this.r > 255) this.r = 255;
    if (this.g < 0) this.g = 0;
    if (this.g > 255) this.g = 255;
    if (this.b < 0) this.b = 0;
    if (this.b > 255) this.b = 255;
    if (this.a < 0) this.a = 0;
    if (this.a > 1) this.a = 1;

    this.r = parseInt(this.r, 10);
    this.g = parseInt(this.g, 10);
    this.b = parseInt(this.b, 10);
    this.a = parseFloat(this.a);

    this.getR = function () {
        return this.r;
    };
    this.getG = function () {
        return this.g;
    };
    this.getB = function () {
        return this.b;
    };
    this.getA = function () {
        return this.a;
    };
    this.getRGBCss = function () {
        return 'rgb(' + this.r + ',' + this.g + ',' + this.b + ')';
    };
    this.getRGBACss = function () {
        return 'rgba(' + this.r + ',' + this.g + ',' + this.b + ',' + this.a + ')';
    };
    this.getHEXCss = function () {
        return "#" + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1);
    };

    this.getRGB = function () {
        return '' + this.r + ',' + this.g + ',' + this.b + '';
    };
    this.getRGBA = function () {
        return '' + this.r + ',' + this.g + ',' + this.b + ',' + this.a + '';
    };
    this.getHEX = function () {
        return "" + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1);
    };

    this.addDeltaR = function (r) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;
        return this;
    };
    this.addDeltaG = function (g) {
        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;
        return this;
    };
    this.addDeltaB = function (b) {
        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;
        return this;
    };
    this.addDeltaA = function (a) {
        this.a = this.a + a;
        if (this.a > 1) this.a = 1;
        if (this.a < 0) this.a = 0;
        return this;
    };

    this.addDeltaRGB = function (r, g, b) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;

        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;

        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;
        return this;
    };

    this.addDeltaRGBA = function (r, g, b, a) {
        this.r = this.r + r;
        if (this.r > 255) this.r = 255;
        if (this.r < 0) this.r = 0;

        this.g = this.g + g;
        if (this.g > 255) this.g = 255;
        if (this.g < 0) this.g = 0;

        this.b = this.b + b;
        if (this.b > 255) this.b = 255;
        if (this.b < 0) this.b = 0;

        this.a = this.a + a;
        if (this.a > 1) this.a = 1;
        if (this.a < 0) this.a = 0;
        return this;
    };


};

hx.getScrollPosWindow = function () {
    var x = 0, y = 0;
    if (typeof( window.pageYOffset ) == 'number') {
        //Netscape compliant
        y = window.pageYOffset;
        x = window.pageXOffset;
    } else if (document.body && ( document.body.scrollLeft || document.body.scrollTop )) {
        //DOM compliant
        y = document.body.scrollTop;
        x = document.body.scrollLeft;
    } else if (document.documentElement &&
        ( document.documentElement.scrollLeft || document.documentElement.scrollTop )) {
        //IE6 standards compliant mode
        y = document.documentElement.scrollTop;
        x = document.documentElement.scrollLeft;
    }
    var obj = new Object();
    obj.left = x;
    obj.top = y;
    return obj;
};


hx.displayLoader = function (name, w, h, r, g, b) {
    if (!w) w = 500;
    if (!h) h = 50;
    if (!r) r = 255;
    if (!g) g = 230;
    if (!b) b = 129;

    var loader = hx.obj(name + '_loader');
    var overlay = hx.obj(name + '_overlay');
    if (!overlay) {
        overlay = document.createElement('div');
        overlay.id = name + '_overlay';
        document.body.appendChild(overlay);
    }
    overlay.style.height = '50px';
    overlay.style.width = '500px';
    if (!loader) {
        loader = document.createElement('div');
        loader.id = name + '_loader';
        overlay.appendChild(loader);
    }
    hx.guiOverlayOpen(overlay, null, null, 100000000);
    hx.guiLoaderIconDisplay('loader', loader, w, h, r, g, b);
};


hx.killLoader = function (name) {
    var loader = hx.obj(name + '_loader');
    var overlay = hx.obj(name + '_overlay');

    hx.guiOverlayClose(overlay);
    hx.guiLoaderIconKill('loader');
};


//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//###################################################################################                      ####################################################################################################
//################################################################################### Ab hier Enstellungen ####################################################################################################
//###################################################################################                      ####################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################
//######################################################################################################################################################################################################


/*
 Backspace-Taste im Dokument abfangen:
 Zur�ckspringen auf vorherige Seite verhindern
 */
hx.onPageReady(function () {
    hx.eventAddCall(document.body, "keydown", function (e) {
        if (!e) e = window.event;
        if (!e.srcElement) e.srcElement = e.target;
        if (hx.value_in(e.srcElement.tagName.toUpperCase(), "INPUT", "TEXTAREA", "DIV")) {
            // alles ok, nix tun
        } else {
            if (e.keyCode == 8) { // Backspace abfangen
                e.returnValue = false;
                if (e.preventDefault) e.preventDefault();
            }
        }
    });
});


$(document).ready(function () {
    // Wenn Submenu existiert: beim vertical scrollen fixieren
    if ($('.submenubox').length) {
        var num = $('.submenubox').position().top - 35; //Default Top-Abstand vom Submenu
        var stop = $(window).scrollTop();
        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > num) {
                //$('.submenubox').addClass('submenubox_fixed'); //bei vertikal Scrollen Submenu fixieren, sobald dieses am oberen Rand ankommt
                //$('.submenubox').css({'left': -$(this).scrollLeft()}); //bei horizontalem Scrollen left position von fixiertem submenu anpassen

                //console.log($('.mainmenubox').offset().top);
                //$('.mainmenubox').offset({top:0, left:0});
                //$('.modulmenubox').offset({top:0, left:0});

                //$('.headerbox').addClass('headerbox_fixed'); //bei vertikal Scrollen Submenu fixieren, sobald dieses am oberen Rand ankommt
                //$('.headerbox').css({'left': -$(this).scrollLeft()}); //bei horizontalem Scrollen left position von fixiertem submenu anpassen
                //$('.copylink').hide();

            } else {
                //$('.submenubox').removeClass('submenubox_fixed');
                //$('.submenubox').css({'left': 0});

                //$('.headerbox').removeClass('headerbox_fixed');
                //$('.headerbox').css({'left': 0});
                //$('.copylink').show();
            }
        });
    }

    $('ul.hxmenu li').on('click', function (event) {
        var linkObj = $(this).find('a').first();
        var nextObj = linkObj.next().first();
        var href = linkObj.prop('href');

        if (!nextObj || !linkObj || typeof href === "undefined" || !hx.startsWith(href, 'javascript')) {
            event.stopPropagation();
            return;
        }

        if ($(nextObj).prop('tagName') == 'UL') {
            if ($(nextObj).hasClass('hidden')) {
                $(nextObj).slideDown('fast', function () {
                    $(nextObj).removeClass('hidden');
                });
            } else {
                $(nextObj).slideUp('fast', function () {
                    $(nextObj).addClass('hidden');

                });
            }
        }

        event.stopPropagation();
    });
});
$(document).ready(function () {
    //Tooltips dynamisch erzeugen wenn textbreite groesser spaltenbreite
    $(".hxlistrow td:not(.listcelldeletebtn)").on("mouseenter", function () {
        if ($(this).is("[title],[data-notooltip]")) return;

        var uniqueClassName = "hxlist-tooltip-text";
        var html = $(this).html();
        var tdWidth = $(this).width();

        $("body").append("<span style=\"visibility:hidden;\" class=\"" + uniqueClassName + "\">" + html + "</span>");
        var tooltipObject = $("." + uniqueClassName);
        var textWidth = tooltipObject.width();
        tooltipObject.remove();

        if (textWidth > tdWidth) $(this).attr("title", $(this).text());
        else $(this).attr("data-notooltip", "1");
    });

    //Zeilenmarkierung via Mausklick on/off
    $(".hxlistrow").on("click", function () {
        hxlist.rowhighlight($(this).get(0));
    });

    /*listsetting*/
    $hxListsettingsToggler = $(".js-hx_listsettings_toggler");
    $hxListsettings = $(".js-hx_listsettings");

    $hxListsettingsToggler.click(function (e) {
        e.preventDefault();
        $hxListsettings.toggle();
        if ($(".hx-window").height() < $hxListsettings.height() + 100) {  //Alte Contentbox (aka Window)
            $hxListsettings.addClass("listsettings-down")
        } else if ($(".hx_contentbox").height() < $hxListsettings.height() + 100) { //Neue Contentbox
                $hxListsettings.addClass("listsettings-down")
        } else {
            $hxListsettings.removeClass("listsettings-down")
        }
    });

    //Auf Touchgeraeten gibt es kein Mouseover: nofilter-Button zeigt beim
    //ersten Touch den filter-Button an, fuehrt dann die eigentliche Aktion aus
    //In Safari und Edge ist overflow=visible bereits Eventausfuehrung, bei Chrome noch nicht
    $('div.listcellfilterbtnbox img').on("touchstart", function (e) {
        if ($('img.listbtn_filter').css('left') != '18px' && $('.listbtn_phonefilter').length==0) {
            $('img.listbtn_filter').css('left', '18px');
            $('div.listcellfilterbtnbox').css('margin-left', '-9px');
            $('div.listcellfilterbtnbox').css('overflow', 'visible'); //Fuer Chrome
            return false;
        } else {
            return true;
        }


    });

});


function hxlist() {
}

hxlist.init = function (formname, fieldname, selectText, list_name, filter_onchange) {

    var field = document[formname][fieldname];
    var field_hidden = document[formname][fieldname + '_hidden'];
    var form = document[formname];
    var listname = list_name;
    var filterOnchange = filter_onchange;

    updateCheckedString();

    hx.eventAddCall(field, 'mousedown', function (e) {
        this.blur();
        displayMultiselectOptions(this);
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;

        return false;
    });
    hx.eventAddCall(field, 'keydown', function (e) {
        if (!e.which) e.which = e.keyCode;
        if (e.which == 13) {
            if (e.preventDefault) e.preventDefault();
            e.returnValue = false;

            form['listActiveElement'].value = field.name;

            form.onsubmit();
            form.submit();

            return false;
        }
    });
    hx.eventAddCall(field, 'keypress', function (e) {
        if (!e.which) e.which = e.keyCode;
        if (hx.value_in(e.which, 32, 40)) {
            displayMultiselectOptions(this);
        }
    });


    function updateCheckedString() {
        var str = "";
        var checkedCount = 0;
        field_hidden.value = "";
        hx.foreach(hx.obj(field.name + '_options').getElementsByTagName("input"), function (cb, i) {
            if (cb.checked) {
                str += ', ' + hx.strip_tags(cb.parentNode.innerHTML);
                checkedCount++;
                if (i > 0) field_hidden.value += cb.value + ",";
            }
        });
        //if(hx.trim(str,', ')=="*") str = "";
        field_hidden.value = hx.trim(field_hidden.value, ", ");

        if (this.blurTrigger == "keyboard") field.focus();
        this.blurTrigger = undefined;
        field.title = hx.trim(str, ', ');

        if (checkedCount == 1) {
            field[0].innerHTML = hx.trim(str, ', ');
        } else {
            field[0].innerHTML = checkedCount + " " + selectText;
        }


        var t = hx.objByCssSelector('li.active', hx.obj(field.name + '_options'))[0];
        if (t) hx.classNameRemove(t, "active");
    }

    function getClickedObjByEvent(event) {

        if (typeof event.originalEvent !== "undefined") {
            // Unterschiedliche Events befuellen unterschiedliche Touchlists
            if (typeof event.originalEvent.touches[0] !== "undefined") {
                return event.originalEvent.touches[0];

            } else if (typeof event.originalEvent.targetTouches[0] !== "undefined") {
                return event.originalEvent.targetTouches[0];

            } else if (typeof event.originalEvent.changedTouches[0] !== "undefined") {
                return event.originalEvent.changedTouches[0];
            }
        }

        return undefined;
    }

    function displayMultiselectOptions(select) {
        displayMultiselectOptions.mousedownElement = null;

        var pos = hx.getAbsoluteObjectPosArray(select);
        var obj = hx.obj(select.name + '_options');
        var searchInsideForElement = true;

        obj.listname = listname;
        obj.filterOnchange = filterOnchange;
        obj.name = select.name;
        obj.style.display = '';
        obj.style.top = pos.top + 'px';
        obj.style.left = pos.left + 'px';
        //obj.style.width = select.style.width;
        obj.onblur = function (evt) {
            var mde = displayMultiselectOptions.mousedownElement;

            if(searchInsideForElement) {
                while (mde && mde.parentNode) {
                    if (mde == this) {
                        displayMultiselectOptions.mousedownElement = null;
                        return;
                    }

                    mde = mde.parentNode;
                }
            }

            searchInsideForElement = true;
            var multiselectHidden = $("#" + this.name + "_hidden");
            var oldval = multiselectHidden.val();
            updateCheckedString();
            //beim verlassen sofort filtern - wenn Filteraenderungen erfolgt sind
            if (filterOnchange == 1 && oldval + '' != multiselectHidden.val() + '') {
                eval('filtern_' + listname + '(1);');
            }
            this.style.display = 'none';

        };
        obj.onmousedown = function (e) {
            if (!e) e = event;
            if (!e.target) e.target = e.srcElement;
            if (e.target.tagName.toUpperCase() == 'DIV') return; // Wenn auf scrollbar geklickt wurde
            displayMultiselectOptions.mousedownElement = e.target;
        };
        obj.onclick = function () {
            displayMultiselectOptions.mousedownElement = null;
        };

        obj.onkeydown = function (e) {
            if (!e) e = event;
            if (!e.which) e.which = e.keyCode;
            if (hx.value_in(e.which, 40, 38, 32)) {
                if (e.preventDefault) e.preventDefault();
                e.returnValue = false;
                return false;
            }
        };

        obj.onkeyup = function (e) {
            if (!e) e = event;
            if (!e.which) e.which = e.keyCode;
            if (hx.value_in(e.which, 13, 27)) {
                obj.blurTrigger = "keyboard";
                this.blur();
                //hx.eventFire(this,"blur");

                obj.style.display = "none";
                field.focus();
                //hx.eventFire(field,"focus");
            } else if (e.which == 40) {//runter

                var t = hx.objByCssSelector('li.active', obj)[0];


                if (!t) {
                    hx.classNameAdd(hx.objByCssSelector('li', obj)[0], "active");
                } else {
                    if (t.nextSibling) {
                        hx.classNameRemove(t, "active");
                        hx.classNameAdd(t.nextSibling, "active");
                    }

                }
            } else if (e.which == 38) {//rauf
                var t = hx.objByCssSelector('li.active', obj)[0];

                if (!t) {
                    hx.classNameAdd(hx.objByCssSelector('li', obj)[0], "active");
                } else {
                    if (t.previousSibling) {
                        hx.classNameRemove(t, "active");
                        hx.classNameAdd(t.previousSibling, "active");
                    }

                }
            } else if (e.which == 32) {//leerzeichen
                var t = hx.objByCssSelector('li.active', obj)[0];
                if (t) {
                    t.click();
                }
            }

            if (t) {
                var p = t.parentNode.parentNode;
                var grenzeUnten = p.offsetHeight + p.scrollTop;
                var grenzeOben = p.scrollTop;

                if (t.offsetTop - t.offsetHeight < grenzeOben && e.which == 38) {
                    p.scrollTop = t.offsetTop - t.offsetHeight;
                } else if (t.offsetTop + t.offsetHeight * 2 > grenzeUnten && e.which == 40) {
                    p.scrollTop = t.offsetTop + t.offsetHeight * 2 - p.offsetHeight;
                }
            }

            if (e.preventDefault) e.preventDefault();
            e.returnValue = false;
            return false;


        };
        setTimeout(function () {
            obj.focus();
        }, 1);


        // Checkbox-Text clickhandler
        hx.foreach(obj.getElementsByTagName('li'), function (li) {
            li.onclick = function (e) {
                if (!e) e = event;
                if (!e.target) e.target = e.srcElement;
                obj.focus();
                displayMultiselectOptions.mousedownElement = null;
                if (e.target.tagName.toUpperCase() == 'INPUT') return;
                var inp = this.getElementsByTagName('input')[0];
                inp.click();
            };
        });

        // Checkbox clickhandler
        hx.foreach(obj.getElementsByTagName('input'), function (cb) {
            cb.onclick = function () {
                if (hx.classNameExists(this, "asterisk")) {
                    if (this.checked) {
                        hx.foreach(this.parentNode.parentNode.getElementsByTagName('input'), function (v, k) {
                            if (k > 0) v.checked = false;
                        });
                    } else {
                        hx.foreach(this.parentNode.parentNode.getElementsByTagName('input'), function (v, k) {
                            if (k > 0) v.checked = true;
                        });
                    }
                } else {
                    if (this.checked) {
                        this.parentNode.parentNode.getElementsByTagName('input')[0].checked = false;
                    } else {
                        var allUnchecked = true;
                        hx.foreach(this.parentNode.parentNode.getElementsByTagName('input'), function (cba) {
                            if (cba.checked) {
                                allUnchecked = false;
                                return false;
                            }
                        });
                        if (allUnchecked) this.parentNode.parentNode.getElementsByTagName('input')[0].checked = true;
                    }
                }
            };
        });

        //close clickhandler
        hx.objByCssSelector('div.close', obj)[0].onclick = function () {
            obj.style.display = "none";
            hx.eventFire(field, "focus");

            searchInsideForElement = false;
            hx.eventFire(obj, "blur");
        };


        function tabCatch(e) {

            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 9 && hx.classNameExists(e.target, 'multiselect_options')) {
                if (e.preventDefault) e.preventDefault();
                e.returnValue = false;
                obj.blur();
                var parent = e.target.parentNode;

                while (parent.tagName.toUpperCase() != 'TR') parent = parent.parentNode;
                var found = false;
                var filterfields = new Array();
                hx.foreach(hx.objByCssSelector('td>input,td>select', parent), function (f) {
                    filterfields.push(f);
                });

                if (e.shiftKey) filterfields.reverse();
                hx.foreach(filterfields, function (o) {
                    if (o.type == 'hidden') {
                        return true;
                    }
                    if (found) {
                        o.focus();
                        return false;
                    }

                    if (o == form[hx.substr(obj.id, 0, obj.id.length - '_options'.length)]) {
                        found = true;
                    }
                });
            }
        }

        try {
            hx.eventRemoveCall(document.body, 'keydown', tabCatch);
        } catch (e) {

        }
        hx.eventAddCall(document.body, 'keydown', tabCatch);


    }

};

hxlist.rowhighlight = function (trObj) {

    if ($(trObj).hasClass('hxlistrowhighlight')) {
        $(trObj).removeClass('hxlistrowhighlight');
    } else {
        $(trObj).addClass('hxlistrowhighlight');
    }

};


/** F�r alte Liste **/
hxlist.listrowhighlight = function (trObj) {
    if ($(trObj).hasClass('listrowhighlight')) {
        $(trObj).removeClass('listrowhighlight');
    } else {
        $(trObj).addClass('listrowhighlight');
    }
};

/*
hxlist.highlighrow = function (obj, rowcolor, rowcolorselected) {
    if (obj.getAttribute('mark', false) == '1') {
        obj.style.backgroundColor = rowcolor;
        obj.setAttribute('mark', '0');
    } else {
        obj.style.backgroundColor = rowcolorselected;
        obj.setAttribute('mark', '1');
    }
    hx.forceBrowserRepaint();
};
*/
hxlist.isValidText = function (t) {
    var e = "<>\\\"";
    for (n = 0; n < t.length; n++) {
        if (e.indexOf(t.charAt(n)) >= 0) return false;
    }
    return true;
};


hxlist.isFloat = function (t) {
    var e = "01234567890,";
    for (n = 0; n < t.length; n++) {
        if (e.indexOf(t.charAt(n)) < 0) return false;
    }
    return true;
};


hxlist.isInteger = function (t) {
    var e = "01234567890";
    for (n = 0; n < t.length; n++) {
        if (e.indexOf(t.charAt(n)) < 0) return false;
    }
    return true;
};

hxlist.filterkeypressed = function (evt, listname) {
    e = (window.event) ? event.keyCode : evt.which;
    if (e == 13) eval('filtern_' + listname + '(1);');
};


hxlist.listSettingsUpdate = function (val, root, listname, formname) {
    //die aktuellen Settings sollen gepeichert werden
    document.forms[formname].listSettings.value = 1;
    //filter zuruecksetzen und liste neu laden
    window["filtern_" + listname](-1);
};
/**
 * @deprecated
 */
function getKW(datum) {
    var jahr = datum.getFullYear();
    var jh = jahr + 1;
    var kalwo = _kaldiff(datum, jh);
    while (kalwo < 1) {
        jh--;
        kalwo = _kaldiff(datum, jh);
    }
    return kalwo;
}

/**
 * @deprecated
 */
function _kaldiff(datum, jahr) {
    var d4j = new Date(jahr, 0, 4);
    var wt4j = (d4j.getDay() + 6) % 7;
    return Math.floor(1.05 + (datum.getTime() - d4j.getTime()) / 6048e5 + wt4j / 7);
}


/**
 * @deprecated
 */
function getDatumFromKW(kw, jahr) {
    var datum = new Date(jahr, 6, 1);
    while (getKW(datum) > kw && datum.getFullYear() == jahr) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() - 1);
    while (getKW(datum) < kw && datum.getFullYear() == jahr) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() + 1);
    // Montag
    while (getKW(datum) == kw) datum = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() - 1);
    return new Date(datum.getFullYear(), datum.getMonth(), datum.getDate() + 1);
}

/**
 * @deprecated
 */
function dateDiff(strDate1, strDate2) {
    datDate1 = Date.parse(strDate1);
    datDate2 = Date.parse(strDate2);
    return ((datDate2 - datDate1) / (24 * 60 * 60 * 1000));
}

/**
 * @deprecated
 */
function format00(s) {
    t = "" + s;
    if (t.length < 2) t = "0" + t;
    if (t.length < 2) t = "0" + t;
    return t;
}

/**
 * @deprecated
 */
function FormatPreis(wert, nachkomma) {
    if (isNaN(wert) || wert + "" == "") return "";
    zahl = parseFloat(wert);
    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    return erg;
}


/**
 * @deprecated
 */
function formatPreis(wert, nachkomma) {
    if (isNaN(wert) || wert + "" == "") return "";
    zahl = parseFloat(wert);
    var n = 0;
    zahl = Math.round(zahl * Math.pow(10, nachkomma)) / Math.pow(10, nachkomma);
    if (zahl.toFixed) zahl = zahl.toFixed(nachkomma);
    var t = "" + zahl;
    var z = "";
    for (n = 0; n < t.length; n++) {
        if (t.substr(n, 1) == ".") {
            z = z + ",";
        } else {
            z = z + t.substr(n, 1);
        }
    }
    var pos = z.indexOf(",");
    if (pos < 0) pos = z.length;
    var erg = z.substr(pos, 100);
    var c = 0;
    for (n = pos - 1; n >= 0; n--) {
        if (c == 3) {
            erg = "." + erg;
            c = 1;
        } else {
            c++;
        }
        erg = z.substr(n, 1) + erg;
    }
    return erg;
}

/**
 * @deprecated
 */
function replace(textstr, findstr, repstr) {
    var t = '', f = '', r = '', n = 0, npos = 0;
    t = '' + textstr;
    f = '' + findstr;
    r = '' + repstr;
    if ((f != "") && (f != r)) {
        npos = t.indexOf(f);
        while (npos >= 0) {
            t = t.substr(0, npos) + r + t.substr(npos + f.length);
            n = npos + r.length;
            npos = t.substr(n).indexOf(f);
            if (npos >= 0) npos = npos + n;
        }
    }
    return t;
}

/**
 * @deprecated
 */
function FormatKomma(wert, komma) {
    var pos, n, vor = "", nach = "", erg = "", content = "";
    wert = wert * Math.pow(10, komma);
    wert = Math.round(wert);
    wert = wert / Math.pow(10, komma);
    cont = "" + wert;
    content = "";
    content = cont.replace(/\./, ",");
    pos = content.indexOf(",");
    if (pos < 0) pos = content.length;
    vor = content.substr(0, pos);
    nach = content.substr(pos + 1, 100);
    if (nach.length < komma) nach = nach + "0";
    if (nach.length < komma) nach = nach + "0";
    for (n = vor.length; n >= 0; n--) {
        erg = String(vor.charAt(n)) + String(erg);
    }
    return String(erg) + "." + String(nach);
}

/**
 * @deprecated
 */
function httpRequest(url, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");

            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) http_request.onreadystatechange = function () {
        if (http_request.readyState == 4) {
            callbackfunction(http_request.responseText);
        }
    };
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('GET', url, true);
            http_request.send(null);
        } else {
            http_request.open('GET', url, false);
            http_request.send(null);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
}

/**
 * @deprecated
 */
function httpPostRequest(url, postdata, callbackfunction) {
    var http_request = false;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            // http_request.overrideMimeType('text/plain');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }
    if (!http_request) {
        alert('ERROR: AJAX NOT AVAILABLE');
        return false;
    }
    if ('function' == typeof callbackfunction) http_request.onreadystatechange = function () {
        if (http_request.readyState == 4) {
            callbackfunction(http_request.responseText);
        }
    };
    try {
        if ('function' == typeof callbackfunction) {
            http_request.open('POST', url, true);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
        } else {
            http_request.open('POST', url, false);
            http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http_request.send(postdata);
            return http_request.responseText;
        }
    } catch (e) {
    }
    if ('function' != typeof callbackfunction) return "";
}

/**
 * @deprecated
 */
function isValidString(str, format) {
    var a = "";
    if (format == 'INT') {
        a = '0123456789';
    }
    else if (format == 'FLOAT') {
        a = '0123456789.';
    }
    else if (format == 'azAZ') {
        a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'azAZ09') {
        a = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else if (format == 'FILENAME') {
        a = '0123456789_-.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    else {
        a = format;
    }

    var c = '';
    merker = true;
    for (n = 0; n < str.length; n++) {
        c = str.substr(n, 1);
        if (a.indexOf(c) < 0) {
            merker = false;
            break;
        }
    }
    return merker;
}

/**
 * @deprecated
 */
function FixLen(t, len) {
    while (t.length < len) {
        t = t + " ";
    }
    return t.substr(0, len);
}


function open_popup(url, w, h, winname) {
    var wi = window.open(url, winname, "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,width=" + w + ",height=" + h + ",resizable=yes,dependent=1").focus();
    try {
        wi.focus();
    } catch (e) {
    }
}





